import React from "react";

const InputComponent = ({ label, placeholderText, type ,onChange,value}) => {
  return (
    <>
      <div className="my-6">
        <p className="font-[500] text-black text-[20px] max-[750px]:text-lg  leading-[30px] mb-4 max-[750px]:mb-2">
          {label}
        </p>
        <input
          type={type}
          className="w-full outline-none border-[2px] border-[#EFEBEB] rounded-lg h-[60px] max-[750px]:h-[50px] px-5 max-[750px]:px-3"
          placeholder={placeholderText}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default InputComponent;
