import React, { useEffect, useState } from "react";
import Hsidebar from "../../Component/Hospital/Layout/Hsidebar";
import { PiArrowLeftFill } from "react-icons/pi";
import PImage from "../../images/patientImage.png";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const data = {
  labels,
  datasets: [
    {
      label: "Translations",
      data: [6, 20, 30, 20, 30, 50, 10, 13, 34, 23, 60, 9],
      borderColor: "#FE981E",
      backgroundColor: "#FE981E",
    },
    {
      label: "Transcriptions",
      data: [20, 35, 30, 20, 40, 50, 20, 40, 26, 37, 22, 8],
      borderColor: "#0F123F",
      backgroundColor: "#0F123F",
    },
  ],
};

export const DoctorDetailProfile = () => {
  const [doctorDetail, setDoctorDetail] = useState();
  const [patientNumber, setPatietnNumber] = useState();

  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {   
    loadSingleDoctor();
  }, []);
  const loadSingleDoctor = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/doctor/detail`,
      data: { doctorId: params?.doctorId },
    })
      .then((response) => {
        console.log("Get Doctor Detail", response);
        setDoctorDetail(response?.data);
        // setPatients(response.data);
      })
      .catch((error) => {
        console.log("Get Patient ERROR", error.response);
      });
  };

  useEffect(() => {
    loadPatientNumber();
  }, []);
  const loadPatientNumber = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/doctor/patient/${params?.doctorId}`,
    })
      .then((response) => {
        setPatietnNumber(response?.data);;
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };

  const handleDeleteDoctor = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this doctor?"
    );
    if (isConfirmed) {
      axios({
        method: "DELETE",
        url: `${BASE_URL}/delete-doctor/${params?.doctorId}`,
      })
        .then((response) => {
          console.log("Delete Doctor", response.data);
          toast.success("Doctor Deleted Successfully");
          navigate("/hospital/doctors");
          // setToggle(!toggle);
        })
        .catch((error) => {
          console.log("Delete Patient Notes Error", error.response);
        });
    }
  };

  const handleDeactivateDoctor = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to deactivate this doctor?"
    );
    if (isConfirmed) {
      axios({
        method: "DELETE",
        url: `${BASE_URL}/deactivate-doctor/${params?.doctorId}`,
      })
        .then((response) => {
          console.log("Delete Doctor", response.data);
          toast.success("Doctor Deactivated Successfully");
          navigate("/hospital/doctors");
          // setToggle(!toggle);
        })
        .catch((error) => {
          console.log("Delete Patient Notes Error", error.response);
        });
    }
  };
  return (
    <Hsidebar activeLink="Doctors">
      <div className="h-[290px] w-[100%] bg-[#67A599] dark:bg-[var(--dark-primary)] p-[20px] text-[#fff]">
        <div className="flex items-center justify-between">
          <div className="flex gap-[10px] items-center text-[25px] font-[500]">
            <PiArrowLeftFill className="mt-[5px]" />
            <p>Doctors</p>
          </div>
          <div className="flex items-center justify-center gap-4">
            <button className="w-[151px] h-[47px] bg-[#FF0000] rounded-full font-inter font-[700] text-[14px] mt-[15px] "
            onClick={handleDeactivateDoctor}>
              Deactivate
            </button>
            <button
              className="w-[151px] h-[47px] bg-[#FF0000] rounded-full font-inter font-[700] text-[14px] mt-[15px] "
              onClick={handleDeleteDoctor}
            >
              Delete
            </button>
          </div>
        </div>
        <div className="flex mt-[40px] items-center">
          <div className="flex gap-[15px] items-center border-r-[1px] border-[#fff] pr-[50px] h-[100px]">
            <img
              src={PImage}
              className="w-[103px] h-[103px] rounded-full border-[5px] border-[#fff]"
              alt=""
            />
            <div>
              <p className="font-[600] text-[29px] leading-[40px]">
                {doctorDetail?.fullName}
              </p>
              <p className="font-[500] text-[19px] leading-[30px]">
                {doctorDetail?.department?.departmentName}
              </p>
              <p className="text-[17px] text-[#FFCFAA]">
                {doctorDetail?.email}
              </p>
            </div>
          </div>
          <div className="px-[40px] border-r-[1px] border-[#fff] h-[100px] ">
            {/* <div className="flex gap-[20px] items-center my-[15px] ">
              <button className="w-[151px] h-[29px] bg-[#67A599] rounded-full">
                Gender
              </button>
              <p className="text-[#FFCFAA] text-[17px]">Female</p>
            </div> */}
            <div className="flex justify-center gap-[20px] items-center h-[100px]">
              <button className="w-[151px] h-[29px] bg-[#67A599] rounded-full">
                Date Joined
              </button>
              <p className="text-[#FFCFAA] text-[17px]">
                {moment(new Date(doctorDetail?.createdAt)).format(
                  "MMMM Do YYYY, h:mm:ss a "
                )}
              </p>
            </div>
          </div>
          <div className="px-[40px]">
            <p className="font-inter font-[700] text-[70px] leading-[60px]">
              {patientNumber}
            </p>
            <p className="font-inter font-[700] text-[18px]">Patients</p>
          </div>
        </div>
      </div>
      <div className="p-[20px]">
        <div className="p-[20px] bg-[#67A599] dark:bg-[var(--dark-primary)] w-[100%] h-[500px]  my-[20px]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-[20px]">
              <p>Transcript Analytics</p>

              <select
                id="countries"
                className="w-[120px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
              >
                <option selected>All Doctors</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
              </select>
            </div>

            <div className="flex items-center gap-[15px]  mb-[25px]">
              <div className="flex items-center gap-[10px]">
                <div className="bg-[#0F123F] w-[8px] h-[8px] rounded-full"></div>
                <div className="font-outfit text-[12px]">Transcriptions</div>
              </div>
              <div className="flex items-center gap-[10px]">
                <div className="bg-[#FE981E] w-[8px] h-[8px] rounded-full"></div>
                <div className="font-outfit text-[12px]">Translations</div>
              </div>
              <select
                id="countries"
                className="w-[100px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
              >
                <option selected>Monthly</option>
                <option value="US">Yearly</option>
                <option value="CA">Weekly</option>
                <option value="FR">Daily</option>
              </select>
            </div>
          </div>
          <Line
            className="w-[100%]"
            width={900}
            height={350}
            options={options}
            data={data}
          />
        </div>
      </div>
    </Hsidebar>
  );
};
