import React, { useState } from "react";
import AddDoctorContent from "../../popups/AddDoctorContent";

const AddDoctor = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);
  return (
    <div>
      <button
        onClick={toggleModal}
        className="w-[140px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]"
      >
        <img
          className="w-[24px] h-[24px]"
          src={require("../../../../images/add-circle.png")}
          alt=""
        />
        <span className="ml-1 text-[12px] font-[500]">New Doctor</span>
      </button>

      {/* Modal */}
      {isOpen && <AddDoctorContent toggleModal={toggleModal} />}
    </div>
  );
};

export default AddDoctor;
