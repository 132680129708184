import React, { useEffect, useState } from "react";
import HospitalDashboardLayout from "../../layout/hospital";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoToggleOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import Dpimg from "../../../images/dp.png";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import EditCustomPopup from "../../component/popups/EditCustomPopup";

const DoctorDetail = () => {
  const params = useParams();
  const [doctorDetail, setDoctorDetail] = useState();
  const [doctorStatus, setDoctorStatus] = useState();
  const [patientNumber, setPatietnNumber] = useState();
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [staffId, setStaffid] = useState("");
  const [disableEdit, setDisableEdit] = useState(true);
  const [refreshDoctor, setRefreshDoctor] = useState(false);
  const [isDeactivatePopup, setisDeactivatePopup] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);

  useEffect(() => {
    loadSingleDoctor();
  }, [refreshDoctor]);
  const loadSingleDoctor = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/doctor/detail`,
      data: { doctorId: params?.id },
    })
      .then((response) => {
        console.log("Get Doctor Detail", response);
        const createdAtMilliseconds = new Date(
          response?.data?.updatedAt
        ).getTime();
        const fiveMinutesLater = new Date(
          // createdAtMilliseconds + 3 * 60000 * 60 * 24
          createdAtMilliseconds + 3 * 60000 * 60 * 24
        );
        const currentTime = new Date();
        if (
          response?.data?.status === "Pending" &&
          currentTime >= fiveMinutesLater
        ) {
          setDoctorStatus("Expired");
        } else {
          setDoctorStatus(response?.data?.status);
        }

        setDoctorDetail(response?.data);
        setFullName(response.data.fullName);
        setMobile(response.data.mobile);
        setStaffid(response.data.staffId);

        // setPatients(response.data);
      })
      .catch((error) => {
        console.log("Get Patient ERROR", error.response);
      });
  };

  useEffect(() => {
    loadPatientNumber();
  }, []);
  const loadPatientNumber = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/doctor/patient/${params?.id}`,
    })
      .then((response) => {
        setPatietnNumber(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };

  const handleEditDoctor = () => {
    axios({
      method: "PUT",
      url: `${BASE_URL}/hospital/update-doctor/${params.id}`,
      data: { fullName, staffId, mobile },
    })
      .then((response) => {
        console.log("Update Doctor Response", response);
        toast.success("Doctor Profile Updated Successfully");
        setRefreshDoctor(!refreshDoctor);
        setDisableEdit(true);
        // setPatients(response.data);
      })
      .catch((error) => {
        console.log("Get Patient ERROR", error.response);
      });
  };
  return (
    <HospitalDashboardLayout>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-black font-[500] text-[32px] leading-[44px]">
            Doctor
          </h2>

          <div className="flex items-center mt-[2px] ">
            <MdKeyboardArrowRight className=" text-[#B0B0B0]" size={25} />
            <MdKeyboardArrowRight size={32} className="-ml-[19px] text-grey" />
          </div>
          <h3 className="text-lg font-[500]">{fullName}</h3>
        </div>

        <div className="flex items-center gap-3">
          {doctorStatus == "Expired" && (
            <button className="w-[141px] h-[45px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#FF3B30] rounded-full flex items-center justify-center gap-2">
              <div className="w-[5px] h-[5px] rounded-full bg-[#FF3B30] "></div>
              <p className="text-xs font-[500] text-[#FF3B30]">Expired</p>
            </button>
          )}

          {doctorStatus == "Pending" && (
            <button className="w-[141px] h-[45px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#FFCC00] rounded-full flex items-center justify-center gap-2">
              <div className="w-[5px] h-[5px] rounded-full bg-[#FFCC00] "></div>
              <p className="text-xs font-[500] text-[#FFCC00]">Pending</p>
            </button>
          )}
          {doctorStatus == "Active" && (
            <button className="w-[141px] h-[45px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#47BD68] rounded-full flex items-center justify-center gap-2">
              <div className="w-[5px] h-[5px] rounded-full bg-[#47BD68] "></div>
              <p className="text-xs font-[500] text-[#47BD68]">Active</p>
            </button>
          )}
          {doctorStatus == "InActive" && (
            <button className="w-[141px] h-[45px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-grey rounded-full flex items-center justify-center gap-2">
              <div className="w-[5px] h-[5px] rounded-full bg-grey "></div>
              <p className="text-xs font-[500] text-grey">InActive</p>
            </button>
          )}
          {/* <button className="w-[154px] h-[45px] bg-[#47BD681A] border-[0.7px] border-[#34C759] gap-2 flex justify-center items-center rounded-full ">
            <div className="w-[5px] h-[5px] rounded-full bg-[#34C759]"></div>
            <p className="text-[#34C759] text-sm font-[500]">Active</p>
          </button> */}

          <button
            className="w-[141px] h-[45px] bg-[#FFCC00]  gap-2 flex justify-center items-center rounded-full "
            onClick={() => setisDeactivatePopup(true)}
          >
            <IoToggleOutline />
            <p className=" text-sm font-[500]">Deactivate</p>
          </button>

          <button
            className="w-[154px] h-[45px] bg-white border-[0.7px] border-[#FF3B30] gap-2 flex justify-center items-center rounded-full "
            onClick={() => setIsDeletePopup(true)}
          >
            <AiOutlineDelete className="text-[#FF3B30]" />
            <p className=" text-sm font-[500]">Delete Profile</p>
          </button>
        </div>
      </div>

      <div className="w-full h-[194px] flex flex-col justify-between items-center bg-white mt-[50px] rounded-[20px] font-poppins p-[30px]">
        <div className="w-full h-full flex justify-between   ">
          <div className="w-[370px] h-full flex items-center gap-4 ">
            <img
              src={Dpimg}
              className="w-[100px] h-[100px] rounded-full"
              alt=""
            />
            <div className="">
              <h3 className="text-[32px] font-[500]">
                {" "}
                {doctorDetail?.fullName}
              </h3>
              <p className="text-lg font-[500]">
                {doctorDetail?.department?.departmentName}
              </p>
              <p className="text-[16px] font-[500] text-grey">
                {doctorDetail?.email}
              </p>
            </div>
          </div>

          <div className="w-[245px] h-full flex flex-col justify-between items-center border-r border-[#D7D7D7] border-l">
            <p className="text-[16px] font-[500] text-grey">Patients</p>
            <p className="text-[64px] font-[500]">{patientNumber}</p>
          </div>

          <div className="w-[310px] h-full flex flex-col justify-between ">
            <div>
              <p className="text-[16px] font-[500] text-grey">Date Joined</p>
              <p className="text-[16px] font-[500]">
                {moment(new Date(doctorDetail?.createdAt)).format(
                  "MMMM Do YYYY, h:mm:ss a "
                )}
              </p>
            </div>
            {disableEdit ? (
              <button
                className=" px-4 rounded-full bg-white  flex items-center justify-center h-[45px] border-[0.7px] border-[#D7D7D7] w-[127px]"
                onClick={() => setDisableEdit(false)}
              >
                <BiEditAlt className="text-grey text-[22px]" />
                <span className="ml-1 text-[12px] font-[500]">
                  Edit Profile
                </span>
              </button>
            ) : (
              <button
                className=" px-4 rounded-full bg-secondary  text-white flex items-center justify-center h-[45px] border-[0.7px] border-[#D7D7D7] w-[127px]"
                onClick={handleEditDoctor}
              >
                <BiEditAlt className="text-white text-[22px]" />
                <span className="ml-1 text-[12px] font-[500]">Update</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="mt-[50px] w-full rounded-[20px] p-[25px] bg-white">
        <div className="flex justify-between ">
          <div className="w-[45%]">
            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Full Name</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                disabled={disableEdit}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Email Address</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value={doctorDetail?.email}
                disabled={true}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Department</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value={doctorDetail?.department?.departmentName}
                disabled={true}
              />
            </div>
          </div>

          <div className="w-[45%]">
            {/* <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Last Name</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value=" Tripathi"
                disabled={true}
              />
            </div> */}

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Phone Number</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                disabled={disableEdit}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Staff ID Number</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value={staffId}
                onChange={(e) => setStaffid(e.target.value)}
                disabled={disableEdit}
              />
            </div>
          </div>
        </div>
      </div>

      <EditCustomPopup
        isOpen={isDeactivatePopup}
        onClose={() => setisDeactivatePopup(false)}
      >
        <div className="w-[550px] bg-white rounded-[20px] border-[.7px] h-[333px] border-[#D7D7D7] p-[35px] flex items-center justify-center flex-col">
          <h3 className="text-[33px] font-[500]">Confirm Deactivate</h3>
          <p className="w-[316px] text-center text-[16px] leading-[21px] font-[500] text-grey">
            Are you sure , you want to deactivate Doctor
          </p>
          <button className="w-[370px] bg-[#FFCC00] rounded-[10px] py-[10px] text-lg font-semibold mt-2 text-white">
            Deactivate Doctor
          </button>

          <button
            className="text-lg font-[500] border-b text-grey border-grey mt-1"
            onClick={() => setisDeactivatePopup(false)}
          >
            Cancel
          </button>
        </div>
      </EditCustomPopup>

      <EditCustomPopup
        isOpen={isDeletePopup}
        onClose={() => setIsDeletePopup(false)}
      >
        <div className="w-[550px] bg-white rounded-[20px] border-[.7px] h-[333px] border-[#D7D7D7] p-[35px] flex items-center justify-center flex-col">
          <h3 className="text-[33px] font-[500]">Confirm Delete</h3>
          <p className="w-[316px] text-center text-[16px] leading-[21px] font-[500] text-grey">
            Deleted Doctor cannot be recovered. Do you want to delete?
          </p>

          <button className="w-[370px] bg-[#FF3B30] rounded-[10px] py-[10px] text-lg font-semibold mt-2 text-white">
            Delete Note
          </button>

          <button
            className="text-lg font-[500] border-b text-grey border-grey mt-1"
            onClick={() => setIsDeletePopup(false)}
          >
            Cancel
          </button>
        </div>
      </EditCustomPopup>
    </HospitalDashboardLayout>
  );
};

export default DoctorDetail;
