import React from 'react'
import HospitalDashboardLayout from '../../layout/hospital';
import BreadCrumb from '../../component/breadcrumb';

const HospitalHelp = () => {
    const helpTopics = [
        {
          id: 1,
          title: "Getting Started ",
          time: "5 min",
          banner: require("../../../images/help-resource.png"),
        },
        {
          id: 2,
          title: "Managing Your ",
          time: "3 minutes",
          banner: require("../../../images/help-resource.png"),
        },
        {
          id: 3,
          title: "Understanding Analytics",
          time: "7 minutes",
          banner: require("../../../images/help-resource.png"),
        },
        {
          id: 4,
          title: "Setting Up Notifications",
          time: "4 minutes",
          banner: require("../../../images/help-resource.png"),
        },
      ];
    
      return (
        <HospitalDashboardLayout>
          <BreadCrumb parent={"Help and Support"} page={"Resources"} />
    
          <div className="p-6  ">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
              {helpTopics.map((topic) => (
                <div key={topic.id}>
                  <img
                    src={topic.banner}
                    alt={topic.title}
                    className="w-[250px] h-[250px]  "
                  />
                  <div className="">
                    <h2 className="my-1 text-[18px] font-[500] text-[#282828]">
                      {topic.title}
                    </h2>
                    <p className="text-[14px] font-[500] text-[#7C7C7C]">
                      {topic.time} read
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-10">
              <span className="text-[18px] underline font-[500] text-[#515A9C]">
                Checkout more resources on our website
              </span>
            </div>
          </div>
        </HospitalDashboardLayout>
      );
}

export default HospitalHelp