import React, { useContext, useState } from "react";
import GlobalStorage from "../../../Storage/ContextProvider";
import { isAuth } from "../../../auth/helper";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";

const AddDoctorContent = ({ toggleModal }) => {
  const { hospitalDepartment, refreshDepartment, setRefreshDepartment } =
    useContext(GlobalStorage);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [departmentValue, setDepartmentValue] = useState("");
  const [staffId, setStaffId] = useState("");

  const handleCreateStaff = async (event) => {
    event.preventDefault();
    const data = {
      fullName: ` ${firstName} ${lastName}`,
      email,
      department: departmentValue,
      staffId: staffId,
      hospitalId: isAuth()?._id,
    };
    const staffData = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/registerSingleStaff`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital Staff register Success", response);
        toast.success("Email is sent to the given email");
        setRefreshDepartment(!refreshDepartment);
      })
      .catch((error) => {
        console.log("Staff  Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  return (
    <>
      <div
        //   onClick={toggleModal}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div className="bg-white w-full max-w-md rounded-[20px] shadow-lg p-8">
          <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">
            New Doctor
          </h2>

          {/* Form */}
          <form className="space-y-4">
            {/* First Name */}
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter first name"
              />
            </div>

            {/* Last Name */}
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter last name"
              />
            </div>

            {/* Patient Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Doctor Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter Doctor email"
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Department
              </label>

              <select
                id="countries"
                className="w-full  h-[45px] outline-none bg-transparent rounded-[11px] border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                value={departmentValue}
                onChange={(e) => setDepartmentValue(e.target.value)}
              >
                <option selected value="">
                  Department
                </option>
                {hospitalDepartment?.map((data, index) => {
                  return (
                    <>
                      <option value={data?._id} className="text-[15px]">
                        {data?.departmentName}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Staff ID
              </label>
              <input
                type="text"
                value={staffId}
                onChange={(e) => setStaffId(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter Staff Id"
              />
            </div>

            {/* Submit Button */}
            <div className="flex flex-col justify-end space-x-4">
              <button
                onClick={handleCreateStaff}
                className="px-4 py-2 text-white bg-primary   rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Send Verification link
              </button>
              <button
                type="button"
                onClick={toggleModal}
                className="px-4 py-2 text-gray-600    rounded-md focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddDoctorContent;
