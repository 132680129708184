import React, { useContext, useEffect, useState } from "react";
import HospitalDashboardLayout from "../../../layout/hospital";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../../baseUrl";
import GlobalStorage from "../../../../Storage/ContextProvider";

const DepartmentDetail = () => {
  const params = useParams();
  const [departmentDetails, setDepartmentDetails] = useState();
  const [departmentDoctors, setDepartmentDoctors] = useState();
  const [doctorCount, setDoctorCount] = useState();

  const { hospitalDepartment, hospitalDoctors } = useContext(GlobalStorage);
  useEffect(() => {
    loadDepartmentDetail();
  }, [params?.id]);

  const loadDepartmentDetail = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/department/${params?.id}`,
    })
      .then((response) => {
        console.log("Get Department Detail", response);
        setDepartmentDetails(response?.data);
        // setPatients(response.data);
      })
      .catch((error) => {
        console.log("Get Department Detail  ERROR", error.response);
      });
  };

  useEffect(() => {
    if (!hospitalDepartment || !hospitalDoctors) return;
    const doctorsInDepartment = hospitalDoctors?.filter(
      (doctor) => doctor?.department?._id == params?.id
    );
    setDepartmentDoctors(doctorsInDepartment);
    console.log("doctorssss", doctorsInDepartment);

    const doctorCount = doctorsInDepartment.length;
    setDoctorCount(doctorCount);
  }, [hospitalDoctors, hospitalDepartment]);

  const rows = [
    {
      id: 1,
      name: "Fola Gbade",
      email: "folagbade112@gmail.com",
      departmeant: "Oncology ",
      staffId: "#002153",
      status: "Active",
    },
    {
      id: 1,
      name: "Fola Gbade",
      email: "folagbade112@gmail.com",
      departmeant: "Oncology ",
      staffId: "#002153",
      status: "Active",
    },
    {
      id: 1,
      name: "Fola Gbade",
      email: "folagbade112@gmail.com",
      departmeant: "Oncology ",
      staffId: "#002153",
      status: "Active",
    },
  ];
  return (
    <HospitalDashboardLayout>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-black font-[500] text-[32px] leading-[44px]">
            Departments
          </h2>

          <div className="flex items-center mt-[2px] ">
            <MdKeyboardArrowRight className=" text-[#B0B0B0]" size={25} />
            <MdKeyboardArrowRight size={32} className="-ml-[19px] text-grey" />
          </div>
          <h3 className="text-lg font-[500]">Oncology</h3>
        </div>

        <button className="w-[138px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]">
          <img
            className="w-[24px] h-[24px]"
            src={require("../../../../images/add-circle.png")}
            alt=""
          />
          <span className="ml-1 text-[12px] font-[500]">New Doctor</span>
        </button>
      </div>

      <div className="w-full h-[194px] flex flex-col justify-between items-center bg-white mt-[50px] rounded-[20px] font-poppins p-[30px]">
        <div className="w-full h-full flex justify-between   ">
          <div className="w-[390px] h-full flex flex-col justify-between ">
            <h3 className="text-[32px] leading-[48px] font-[500]">
              {departmentDetails?.departmentName}
            </h3>
            <div className="flex justify-between">
              <div>
                <p className="text-[16px] font-[500] text-grey">
                  Department ID:
                </p>
                <p className="text-lg font-[500] mt-1">
                  {departmentDetails?.departmentId}
                </p>
              </div>

              <div>
                <p className="text-[16px] font-[500] text-grey">
                  Assigned Accounts: 
                </p>
                <p className="text-lg font-[500] mt-1">
                  {departmentDetails?.assignedAccount}
                </p>
              </div>
            </div>
          </div>

          <div className="w-[245px] h-full flex flex-col justify-between items-center border-r border-[#D7D7D7] border-l">
            <p className="text-[16px] font-[500] text-grey">Doctors</p>
            <p className="text-[64px] font-[500]"> {doctorCount}</p>
          </div>

          <div className="w-[310px] h-full flex flex-col justify-between ">
            <div>
              <p className="text-[16px] font-[500] text-grey">Date Created</p>
              <p className="text-[16px] font-[500]">
                October 18th 2024, 6:26:14 am
              </p>
            </div>
            <button className=" px-4 rounded-full bg-white  flex items-center justify-center h-[45px] border-[0.7px] border-[#D7D7D7] w-[227px]">
              <BiEditAlt className="text-grey text-[22px]" />
              <span className="ml-1 text-[12px] font-[500]">
                Manage Assigned Account
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="mt-[50px]">
        <div className="w-full mb-4 h-[53px] rounded-[20px] px-5 py-4 bg-white flex items-center text-[16px] font-[500]">
          <div className="w-[20%]">
            <p className="  text-[#000] text-start">Doctor</p>
          </div>

          <div className="w-[25%]  flex justify-center">
            <p className="  text-[#747474] ">Email Address</p>
          </div>

          <div className="w-[20%]  flex justify-center ">
            <p className="  text-[#747474]">Department</p>
          </div>

          <div className="w-[20%] flex justify-center ">
            <p className="  text-[#747474]">Staff ID Number</p>
          </div>

          <div className="w-[15%] flex justify-end ">
            <p className="  text-[#747474]">Status</p>
          </div>
        </div>

        <div className="w-full bg-white  rounded-[20px] font-poppins">
          {departmentDoctors?.map((row, index) => (
            <div
              key={row.id}
              className={`p-4  w-full text-sm font-[500] flex items-center ${
                index !== rows.length - 1 ? "border-b border-[#D7D7D7]" : ""
              }`}
            >
              <p className="w-[20%] ">{row?.fullName}</p>
              <p className="w-[20%] text-end">{row?.email}</p>
              <p className="w-[20%]  text-end pr-5 ">
                {row?.department?.departmentName}
              </p>
              <p className="w-[20%] text-end pr-6 ">{row?.staffId}</p>
              <div className="w-[20%] flex justify-end">
                {row?.status == "Expired" && (
                  <button className="w-[100px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#FF3B30] rounded-full flex items-center justify-center gap-2">
                    <div className="w-[5px] h-[5px] rounded-full bg-[#FF3B30] "></div>
                    <p className="text-xs font-[500] text-[#FF3B30]">Expired</p>
                  </button>
                )}

                {row?.status == "Pending" && (
                  <button className="w-[100px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#FFCC00] rounded-full flex items-center justify-center gap-2">
                    <div className="w-[5px] h-[5px] rounded-full bg-[#FFCC00] "></div>
                    <p className="text-xs font-[500] text-[#FFCC00]">Pending</p>
                  </button>
                )}
                {row?.status == "Active" && (
                  <button className="w-[100px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#47BD68] rounded-full flex items-center justify-center gap-2">
                    <div className="w-[5px] h-[5px] rounded-full bg-[#47BD68] "></div>
                    <p className="text-xs font-[500] text-[#47BD68]">Active</p>
                  </button>
                )}
                {row?.status == "InActive" && (
                  <button className="w-[100px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-grey rounded-full flex items-center justify-center gap-2">
                    <div className="w-[5px] h-[5px] rounded-full bg-grey "></div>
                    <p className="text-xs font-[500] text-grey">InActive</p>
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </HospitalDashboardLayout>
  );
};

export default DepartmentDetail;
