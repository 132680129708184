import React, { useContext, useEffect, useRef } from "react";
import GlobalStorage from "../../../Storage/ContextProvider";

function EditCustomPopup({ isOpen, children, onClose }) {
  const { isCollapsed, setIsCollapsed } = useContext(GlobalStorage);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the popup
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      // Add event listener when the popup is open
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

 

  if (!isOpen) return null;

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: isCollapsed ? "64px" : "256px", // Use isCollapsed here
      width: isCollapsed ? "95vw" : "85vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    popup: {},
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}
       ref={popupRef}
      >
        <div>{children}</div>
      </div>
    </div>
  );
}

export default EditCustomPopup;
