import React, { useState } from "react";
import { RiUser6Line } from "react-icons/ri";
import InputComponent from "./InputComponent";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../../baseUrl";

const RequestDemo = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [hName, setHname] = useState("");
  const [phnNo, setPhnNo] = useState("");

  const handleConfirmRequest = async () => {
    console.log("data", hName);
    
    if (!hName) {
      return toast.error("Please Enter the Hospital Name");
    }
    if (!email) {
      return toast.error("Please Enter the Email Address");
    }
    if (!fullName) {
      return toast.error("Please Enter Full Name");
    }
    if (!phnNo) {
      return toast.error("Please Enter Phone Number");
    }
    const data = {
      hpName: hName,
      emailId: email,
      phnNo: phnNo,
      fullName: fullName,
    };

    const requestDemo = await axios({
      method: "POST",
      url: `${BASE_URL}/requestdemoform`,
      data: data,
    })
      .then((response) => {
        console.log("Request Demo Success", response);
        toast.success(response?.data);
      })
      .catch((error) => {
        console.log("Hospital Update  Error", error.response.data);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      <div className=" font-poppins">
        {/* <div className="w-full flex justify-center items-center mb-14">
          <button
            className="flex items-center justify-center h-[97px] w-full bg-primary rounded-[20px] gap-3"
            style={{
              boxShadow: " 0px 2px 8.9px 0px #00000040",
            }}
          >
            <div className="p-2 bg-white text-primary rounded-full font-bold">
              <RiUser6Line size={22} />
            </div>
            <h3 className="font-bold text-[35px] leading-[52px] text-white">
              Book Demo
            </h3>
          </button>
        </div> */}

        <div className="">
          <h2 className="font-bold text-[50px] max-[750px]:text-[32px] max-[750px]:leading-[55px] leading-[68px] text-black pb-7 ">
            Book a Demo
          </h2>

          <div className="flex flex-col justify-between h-full">
            <div className="">
              <InputComponent
                type="text"
                label="Full Name"
                placeholderText="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <InputComponent
                type="text"
                label="Hospital Name"
                placeholderText="Hospital Full Name"
                value={hName}
                onChange={(e) => setHname(e.target.value)}
              />

              <InputComponent
                type="text"
                label="Email Address"
                placeholderText="johndoe@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <InputComponent
                type="text"
                label="Phone Number"
                placeholderText="Phone Number"
                value={phnNo}
                onChange={(e) => setPhnNo(e.target.value)}
              />
            </div>
            <button className="bg-primary text-white h-[60px] max-[750px]:h-[50px] w-full text-center font-bold text-xl max-[750px]:text-lg rounded-lg mt-16 "
            onClick={handleConfirmRequest}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDemo;
