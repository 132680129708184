import React from "react";
import SimplifyIcon from "../../../images/simplify.svg";
import TranslateIcon from "../../../images/translate.svg";
import { BsWallet2 } from "react-icons/bs";
import { HiArrowLongRight } from "react-icons/hi2";
import { FiUser } from "react-icons/fi";
import { CiWarning } from "react-icons/ci";


const HomeCards = () => {
  return (
    <>
      <div className="flex items-center gap-3">
        <div className="w-[40%] h-[200px] bg-white rounded-[20px] border-[.7px] border-primary px-[10px] py-[24px] flex flex-col justify-between ">
          <h2 className="text-[28px] font-[500]">Good Morning Malik</h2>
          {/* <p className="text-lg ">What have you to do today?</p> */}

          <div className="  flex justify-between">
            <button className="w-[165px] rounded-full text-white bg-primary flex items-center justify-center h-[50px]">
              <img src={SimplifyIcon} className="mr-1" alt="" />
              <p className="ml-1">Simplify</p>
            </button>

            <button className="w-[165px] rounded-full text-white bg-secondary flex items-center justify-center h-[50px]">
              <img src={TranslateIcon} alt="" />
              <p>Translate</p>
            </button>
          </div>
        </div>

        <div className="w-[20%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <BsWallet2 className="text-[24px] text-[#FF2D55]" />
            <h2 className="text-lg font-[500]">Doctor Reports</h2>
          </div>

          <p className="font-semibold text-[50px] text-[#FF2D55]">5</p>

          <button className="w-[180px] h-[40px] flex items-center font-[500] justify-center border-[0.7px] border-[#B0B0B0] rounded-full text-grey">
            <p className="mr-1 text-[13px]">View reports</p>
            <HiArrowLongRight className="ml-1" />
          </button>
        </div>
        <div className="w-[20%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <FiUser className="text-[24px] text-[#47BD68]" />
            <h2 className="text-lg font-[500]">My Proxies</h2>
          </div>

          <p className="font-semibold text-[50px] text-[#47BD68]">5</p>

          <button className="w-[180px] h-[40px] flex items-center justify-center border-[0.7px] border-[#B0B0B0] rounded-full text-grey font-[500]">
            <p className="mr-1 text-[13px]">View proxies</p>
            <HiArrowLongRight className="ml-1" />
          </button>
        </div>
        <div className="w-[20%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <CiWarning className="text-[24px] text-[#FFCC00]" />
            <h2 className="text-lg font-[500]">My Transcripts</h2>
          </div>

          <p className="font-semibold text-[50px] text-[#FFCC00]">3</p>

          <button className="w-[180px] h-[40px] flex items-center justify-center border-[0.7px] border-[#B0B0B0] rounded-full text-grey">
            <p className="mr-1 text-[13px] font-[500]">View transcripts</p>
            <HiArrowLongRight className="ml-1" />
          </button>
        </div>
      </div>
    </>
  );
};

export default HomeCards;
