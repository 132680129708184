import React, { useEffect, useState } from "react";
import LoginSvg from "../../images/loginSvg.svg";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BASE_URL from "../../baseUrl";
import axios from "axios";
const DoctorPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const [username, setUsername] = useState("");
  const [doctorId, setDoctorId] = useState("");

  const handleSetPassword = async () => {
    // const {password, confirmPassword}
    if (password !== confirmPassword) {
      return toast.error("Password not matched");
    } else {
      const data = {
        password,
        token: params?.token,
      };

      const activateAccount = await axios({
        method: "PUT",
        url: `${BASE_URL}/doctor/activate-account`,
        data: data,
      })
        .then((response) => {
          console.log("Activation Success", response);
          toast.success(response?.data?.message);
          navigate("/login");
          // setDataUser(response.data.user)
        })
        .catch((error) => {
          console.log("Doctor Activation  Error", error.response.data);
          toast.error(error.response.data.error);
        });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get("userName");
    const paramValue2 = searchParams.get("doctorId");
    setUsername(paramValue);
    setDoctorId(paramValue2);
    // Output the value of the parameter
  }, []);

  useEffect(() => {
    loadToken();
  }, [doctorId]);

  const loadToken = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/verify-token`,
      data: { token: params?.token, doctorId: doctorId },
    })
      .then((response) => {
        console.log("Get Token ", response);
      })
      .catch((error) => {
        if (error.response?.data === "Password Already setUp") {
          navigate("/doctor/password-done");
        }
        if (error.response?.data === "Expired link. Signup again") {
          navigate(`/doctor/token-expired/${doctorId}`);
        }
        console.log("Get Token ERROR", error.response);
      });
  };
  return (
    <div className="h-screen bg-white flex justify-center items-center font-poppins ">
      <div
        className=" w-[550px] p-5"
        style={{
          boxShadow: " 0px 0px 25px 0px #00000026",
        }}
      >
        <h2 className="font-semibold text-center text-[32px]">
          Setup Password
        </h2>

        <div className="mt-5">
          <div className="">
            <p className="text-xl font-[500]">Password</p>
            <input
              type="text"
              className="w-full outline-none h-[50px] border-[2px] border-[#EFEBEB] rounded-xl px-3 mt-2"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mt-10">
            <p className="text-xl font-[500]">Confirm Password</p>
            <input
              type="text"
              className="w-full outline-none h-[50px] border-[2px] border-[#EFEBEB] rounded-xl px-3 mt-2"
              placeholder="Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>

        <button
          className="bg-primary text-white w-full text-center mt-11 py-3 rounded-[10px] mb-3"
          onClick={handleSetPassword}
        >
          Set Password
        </button>
      </div>

      <img src={LoginSvg} className="absolute bottom-0 right-0" alt="" />
    </div>
  );
};

export default DoctorPassword;
