import React, { useState } from "react";

const AccordionItem = ({
  index,
  title,
  content,
  children,
  isOpen,
  onToggle,
  className,
}) => (
  <div className="rounded-[20px]">
    <button
      onClick={onToggle}
      className={`w-full flex justify-between items-center px-10 py-10 text-left text-[22px] font-[500] text-[#282828] bg-white hover:bg-gray-100 ${className}`}
    >
      <span>{title}</span>
      <svg
        className={`w-5 h-5 transform transition-transform duration-200 ${
          isOpen ? "rotate-180" : ""
        }`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
    {isOpen && (
      <div
        className={`px-10   py-8 text-gray-600 bg-white ${
          index === 1 && "rounded-b-[20px]"
        } `}
      >
        {children}
      </div>
    )}
  </div>
);

export default AccordionItem;
