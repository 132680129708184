import React, { useState } from "react";
import Sidebar from "../../../component/common/sidebar";
import Header from "../../../component/common/header";
import { FiSun } from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import HomeCards from "../../../component/Pateint/HomeCards";
import { CiSearch } from "react-icons/ci";
import { HiArrowLongRight } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineTranslate } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { PiChatCenteredText } from "react-icons/pi";
import { CiMicrophoneOn } from "react-icons/ci";
import PatientDashboard from "../../../layout/patient/index";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSimplify, setIsSimplify] = useState(true);

  const navigate = useNavigate();

  return (
    <>
      {/* Content */}
      <PatientDashboard>
        <div className="flex items-center justify-between mb-4 ">
          <h2 className="text-3xl font-[500]">Dashboard</h2>
          <div className="w-[260px] flex items-center bg-[#B0B0B0] h-[45px] rounded-full ">
            <div
              className={` ${
                isDarkMode ? "bg-[#B0B0B0] text-white" : "bg-white"
              }  w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full cursor-pointer`}
              onClick={() => setIsDarkMode(false)}
            >
              <FiSun
                className={`${!isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p className="text-[13px] font-[500]">light mode</p>
            </div>
            <div
              className={` ${
                isDarkMode ? "bg-white" : "bg-[#B0B0B0] text-white"
              } w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full px-2 text-[13px] cursor-pointer`}
              onClick={() => setIsDarkMode(true)}
            >
              <MdOutlineDarkMode
                className={`${isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p>dark mode</p>
            </div>
          </div>
        </div>
        <HomeCards />

        <div className="mt-10">
          <h3 className="text-lg font-[500]">Recent</h3>
          <div className="flex w-full gap-4">
            <div className="w-[30%] ">
              <div className="flex items-center my-4  w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
                <CiSearch
                  color="black"
                  className="w-[24px] h-[24px]  absolute left-[10px]"
                />
                <input
                  className=" w-full outline-none ml-[45px] mr-[10px] text-sm placeholder-[#7C7C7C]"
                  type="text"
                  placeholder="Find Report"
                />
              </div>
              {[1, 2, 3]?.map((item, index) => (
                <>
                  <div className="p-[24px] border-[#D7D7D7] rounded-[20px] border-[0.7px] bg-white my-2">
                    <div className="flex items-center">
                      <div className="w-[60%] text-grey">
                        <p className="text-lg font-[500]">04-March-2024</p>
                        <p className="text-xs">9:45 PM</p>
                      </div>
                      <div className="w-[40%]">
                        <button className=" text-xs text-grey flex w-[145px] h-[40px] justify-center items-center rounded-full border-grey border-[.7px]">
                          <p className="mr-1">View transcript</p>
                          <HiArrowLongRight className="ml-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className="w-[70%]">
              <div className="my-4">
                <div className="w-[330px] flex items-center bg-[#515A9C1A] h-[45px] rounded-full ">
                  <div
                    className={` ${
                      isSimplify ? "bg-primary text-white" : " text-grey"
                    }  w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full cursor-pointer`}
                    onClick={() => setIsSimplify(true)}
                  >
                    <BiEditAlt
                      className={`${
                        isSimplify ? "text-white" : "text-grey"
                      } text-xl `}
                    />
                    <p className="text-[13px] font-[500]">Simplify</p>
                  </div>
                  <div
                    className={` ${
                      isSimplify ? " text-grey" : "bg-primary text-white"
                    } w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full px-2 text-[13px] cursor-pointer`}
                    onClick={() => setIsSimplify(false)}
                  >
                    <MdOutlineTranslate
                      className={`${
                        isSimplify ? "text-grey" : "text-white"
                      } text-lg`}
                    />
                    <p>Translate</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="w-1/3 bg-white border border-[#D7D7D7] h-[200px] rounded-[20px] flex justify-center items-center flex-col gap-2">
                  <BsUpload className="text-[45px] text-grey" />
                  <p className="text-lg font-[500]">Upload File</p>
                </div>

                <div
                  className="w-1/3 bg-white border border-[#D7D7D7] cursor-pointer h-[200px] rounded-[20px] flex justify-center items-center flex-col gap-2"
                  onClick={() => {
                    navigate("/v2/patient/dashboard/simplify");
                  }}
                >
                  <PiChatCenteredText className="text-[45px] text-grey" />
                  <p className="text-lg font-[500]">Enter text</p>
                </div>

                <div className="w-1/3 bg-white border border-[#D7D7D7] h-[200px] rounded-[20px] flex justify-center items-center flex-col gap-2">
                  <CiMicrophoneOn className="text-[45px] text-grey" />
                  <p className="text-lg font-[500]">Record speech</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PatientDashboard>
    </>
  );
};

export default Dashboard;
