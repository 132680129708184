import React, { useContext, useEffect, useState } from "react";
import HospitalDashboardLayout from "../../../layout/hospital";
import { CiSearch } from "react-icons/ci";
import { BiEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getCookie, isAuth } from "../../../../auth/helper";
import BASE_URL from "../../../../baseUrl";
import axios from "axios";
import GlobalStorage from "../../../../Storage/ContextProvider";
import AddDepartmentModal from "../../../component/popups/AddDepartmentModal";

const HospitalDepartments = () => {
  const [isManageAccount, setisManageAccount] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [updatedDepartment, setUpdatredDepartment] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const {
    createDepartment,
    setCreateDepartment,
    hospitalProfile,
    refreshDepartment,
    setRefreshDepartment,
    hospitalDepartment,
    setHospitalDepartment,
    hospitalDoctors,
    setHospitalDoctors,
  } = useContext(GlobalStorage);

  useEffect(() => {
    let count = 0;
    let count2 = 0;
    let activeDoctorCount = 0;

    const updatedArray = hospitalDepartment?.map((item) => {
      const matchedItem = hospitalDoctors?.filter(
        (doctor) => doctor?.department?._id === item?._id
      );

      const activeDoctorCount = matchedItem.filter(
        (doctor) => doctor?.status === "Active"
      ).length;
      const array = {
        ...item,
        count: count,
        activeDoctorCount: activeDoctorCount,
      };
      // count = 0;
      // activeDoctorCount = 0;
      // setTotalUsedAccount(count2);
      return array;
    });
    setUpdatredDepartment(updatedArray);
  }, [hospitalDoctors, hospitalDepartment]);

  const rows = [
    {
      id: 1,
      departmeant: "Oncology ",
      dId: "#44162",
      accounts: 7,
      doctors: 23,
    },
    {
      id: 2,
      departmeant: "Oncology ",
      dId: "#44162",
      accounts: 7,
      doctors: 23,
    },
    {
      id: 3,
      departmeant: "Oncology ",
      dId: "#44162",
      accounts: 7,
      doctors: 23,
    },
  ];
  return (
    <HospitalDashboardLayout>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-black font-[500] text-[32px] leading-[44px]">
            Departments
          </h2>

          <div className="flex items-center my-4  w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
            <CiSearch
              color="black"
              className="w-[24px] h-[24px]  absolute left-[10px]"
            />
            <input
              className=" w-full outline-none ml-[45px] mr-[10px] text-sm placeholder-[#7C7C7C]"
              type="text"
              placeholder="Find Department"
            />
          </div>
        </div>

        <div className="flex items-center gap-4">
          {isManageAccount ? (
            <button
              className=" px-4 rounded-full bg-secondary text-white  flex items-center justify-center h-[45px]"
              onClick={() => setisManageAccount(false)}
            >
              <BiEditAlt className=" text-[22px]" />
              <span className="ml-1 text-[12px] font-[500]">Save Changes</span>
            </button>
          ) : (
            <button
              className=" px-4 rounded-full bg-white  flex items-center justify-center h-[45px]"
              onClick={() => setisManageAccount(true)}
            >
              <BiEditAlt className="text-grey text-[22px]" />
              <span className="ml-1 text-[12px] font-[500]">
                Manage Assigned Account
              </span>
            </button>
          )}

          <button
            className="w-[171px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]"
            onClick={toggleModal}
          >
            <img
              className="w-[24px] h-[24px]"
              src={require("../../../../images/add-circle.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] font-[500]">New Department</span>
          </button>
        </div>
      </div>

      <div className="w-full mb-4 h-[53px] rounded-[20px] px-5 py-4 bg-white flex items-center text-[16px] font-[500]">
        <div className="w-[34%]">
          <p className="  text-[#000] text-start">Department</p>
        </div>

        <div className="w-[24%] ">
          <p className="  text-[#747474] ">Department ID</p>
        </div>

        <div className="w-[24%]  ">
          <p className="  text-[#747474]">Assigned Accounts</p>
        </div>

        <div className="w-[18%] flex justify-end ">
          <p className="  text-[#747474]">Active Doctors</p>
        </div>
      </div>

      <div className="w-full bg-white  rounded-[20px] font-poppins">
        {updatedDepartment?.map((row, index) => (
          <div
            key={row.id}
            className={`p-4  w-full text-sm font-[500] flex items-center ${
              index !== updatedDepartment.length - 1
                ? "border-b border-[#D7D7D7]"
                : ""
            }`}
          >
            <Link
              to={`/v2/hospital/department/${row?._id}`}
              className="w-[34%]"
            >
              {row?.departmentName}
            </Link>
            <p className="w-[24%] flex justify-center mr-[80px]">
              {row?.departmentId}
            </p>

            {isManageAccount ? (
              <div className="w-[24%] flex items-center gap-3 ml-[65px]">
                <div className="w-[30px] h-[30px] rounded-lg bg-primary flex justify-center items-center text-white text-[18px]">
                  +
                </div>
                <p>{row?.assignedAccount}</p>
                <div className="w-[30px] h-[30px] rounded-lg bg-primary flex justify-center items-center text-white text-lg">
                  -
                </div>
              </div>
            ) : (
              <p className="w-[24%] flex justify-center mr-[80px]">
                {row?.assignedAccount}
              </p>
            )}

            <p className="w-[18%] flex justify-end mr-[55px]">
              {row?.activeDoctorCount}
            </p>
          </div>
        ))}
      </div>

      {isOpen && <AddDepartmentModal toggleModal={toggleModal} />}
    </HospitalDashboardLayout>
  );
};

export default HospitalDepartments;
