import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/common/sidebar";
import Header from "../../component/common/header";
import GlobalStorage from "../../../Storage/ContextProvider";
import { getCookie, isAuth } from "../../../auth/helper";
import BASE_URL from "../../../baseUrl";
import axios from "axios";

const HospitalDashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const {
    hospitalProfile,
    setHospitalProfile,
    setHospitalDepartment,
    setHospitalDoctors,
    refreshDepartment,
  } = useContext(GlobalStorage);
  const token = getCookie("token");

  useEffect(() => {
    loadHospitalProfile();
  }, []);

  const loadHospitalProfile = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/profile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Get Hospital Profile", response);
        setHospitalProfile(response.data);
      })
      .catch((error) => {
        console.log("Get Hospital Profile ERROR", error.response);
      });
  };

  useEffect(() => {
    loadDepartment();
  }, [refreshDepartment]);

  const loadDepartment = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/departments/${isAuth()?._id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // console.log("Get Department", response);
        setHospitalDepartment(response.data);
      })
      .catch((error) => {
        console.log("Get Department ERROR", error.response);
      });
  };
  useEffect(() => {
    loadDoctor();
  }, [refreshDepartment]);

  const loadDoctor = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/doctors/${isAuth()?._id}`,
    })
      .then((response) => {
        
        // setDoctors(response.data);
        setHospitalDoctors(response.data);
      })
      .catch((error) => {
        console.log("Get Doctors ERROR", error.response);
      });
  };
  const menu = [
    {
      title: "Dashboard",
      route: "/v2/hospital/dashboard",
      logo: require("../../../images/menu-square.png"),
    },
    {
      title: "Departments",
      route: "/v2/hospital/departments",
      logo: require("../../../images/svg/patient.svg").default,
    },
    {
      title: "Doctors",
      route: "/v2/hospital/doctors",
      logo: require("../../../images/svg/patient.svg").default,
    },
    {
      title: "Settings",
      route: "/v2/hospital/settings",
      logo: require("../../../images/settings.png"),
    },
  ];
  return (
    <div className="flex h-screen font-poppins overflow-hidden">
      <Sidebar
        menu={menu}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <div className="flex flex-col flex-1">
        <Header
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        {/* Content */}
        <main className="flex-1 p-4 pt-[20px] w-full overflow-scroll">
          {children}
        </main>
      </div>
    </div>
  );
};

export default HospitalDashboardLayout;
