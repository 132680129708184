import React, { useContext, useEffect, useState } from "react";
import HospitalDashboardLayout from "../../../layout/hospital";
import { FiSun, FiUser } from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import { HiArrowLongRight } from "react-icons/hi2";
import { FaRegFolderOpen } from "react-icons/fa";
import CellIcon from "../../../../images/hospital/cells.svg";
import DoctorIcon from "../../../../images/hospital/doctor-01.svg";
import { PiChartLineLight } from "react-icons/pi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import GlobalStorage from "../../../../Storage/ContextProvider";
import { getCookie } from "../../../../auth/helper";
import axios from "axios";
import BASE_URL from "../../../../baseUrl";
import AddDoctorContent from "../../../component/popups/AddDoctorContent";
import AddDepartmentModal from "../../../component/popups/AddDepartmentModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const HospitalDashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSimplify, setIsSimplify] = useState(true);
  const { hospitalProfile, setHospitalProfile } = useContext(GlobalStorage);
  const token = getCookie("token");
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [totalDepartments, setTotalDepartments] = useState(0);
  const [hospitalAnalytics, setHospitalAnalytics] = useState();
  const [transcriptionData, setTranscriptionData] = useState();
  const [addDoctorPopup, setAddDoctorPopUp] = useState(false);
  const [addDepartmentPopup, setAddDepartmentPopUp] = useState(false);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Dataset 1",
        data: [30, 50, 20, 60, 70, 60, 80, 23, 1, 45, 0, 34],
        borderColor: "#3498db",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          // Gradient for Dataset 1
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "#2A6555");
          gradient.addColorStop(1, "rgba(237, 234, 255, 0)");
          return gradient;
        },
        tension: 0.4, // Add smooth curves
        fill: true,
        pointRadius: 0,
      },
      {
        label: "Dataset 2",
        data: [50, 40, 60, 80, 100, 90, 120, 0, 58, 12, 12, 0],
        borderColor: "#2ecc71",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          // Gradient for Dataset 1
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "#2A6555");
          gradient.addColorStop(1, "rgba(237, 234, 255, 0)");
          return gradient;
        },
        tension: 0.4, // Add smooth curves
        fill: true,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        borderCapStyle: "round", // Rounded corners for the lines
      },
    },
  };
  const toggleDepartment = () => setAddDepartmentPopUp(!addDepartmentPopup);
  const toggleDoctor = () => setAddDoctorPopUp(!addDoctorPopup);

  useEffect(() => {
    loadPatientNumber();
  }, [hospitalProfile]);
  const loadPatientNumber = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/analytics/${hospitalProfile?._id}`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Hospital Analytics", response?.data);
        setTotalPatients(response?.data?.totalPatients);
        setTotalDoctors(response?.data?.totalDoctors);
        setTotalDepartments(response?.data?.totalDepartments);
        setHospitalAnalytics(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };
  useEffect(() => {
    loadTranscriptionData();
  }, [hospitalProfile]);
  const loadTranscriptionData = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/transcription-analytics/${hospitalProfile?._id}`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        setTranscriptionData(response.data);
        console.log(
          "Hospital Analytics",
          response?.data[0].transAndTokensList[0]
        );
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };
  return (
    <HospitalDashboardLayout>
      <div className="flex items-center justify-between my-[10px] px-[10px]">
        <h2 className="text-3xl font-[500]">Dashboard</h2>
        <div className="flex flex-row items-center gap-3">
          <button
            className="w-[140px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]"
            onClick={toggleDoctor}
          >
            <img
              className="w-[24px] h-[24px]"
              src={require("../../../../images/add-circle.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] font-[500]">New Doctor</span>
          </button>

          <button
            className="w-[171px] ml-[10px] rounded-full text-white bg-secondary flex items-center justify-center h-[45px]"
            onClick={toggleDepartment}
          >
            <img
              className="w-[24px] h-[24px]"
              src={require("../../../../images/add-circle.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] font-[500]">New Department</span>
          </button>
          <div className="w-[260px] flex items-center bg-[#B0B0B0] h-[45px] rounded-full ">
            <div
              className={` ${
                isDarkMode ? "bg-[#B0B0B0] text-white" : "bg-white"
              }  w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full cursor-pointer`}
              onClick={() => setIsDarkMode(false)}
            >
              <FiSun
                className={`${!isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p className="text-[13px] font-[500]">light mode</p>
            </div>
            <div
              className={` ${
                isDarkMode ? "bg-white" : "bg-[#B0B0B0] text-white"
              } w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full px-2 text-[13px] cursor-pointer`}
              onClick={() => setIsDarkMode(true)}
            >
              <MdOutlineDarkMode
                className={`${isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p>dark mode</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-5 mt-[25px] px-[10px]">
        <div className="w-[50%] h-[200px] text-white bg-primary rounded-[20px] border-[.7px] border-primary my-[10px] flex flex-col justify-between ">
          <div className="w-full flex items-center h-full">
            <div className="w-[50%] flex justify-center items-center flex-col border-r border-white h-[80%] gap-2 ">
              <div className="flex items-center gap-2 ">
                <img src={CellIcon} className="w-[30px] h-[30px]" alt="" />
                <h3 className="font-poppins font-[500] text-lg leading-[25.2px]">
                  Total Departments
                </h3>
              </div>
              <p className="text-[50px] font-semibold leading-[60px]">
                {totalDepartments}
              </p>

              <button className="flex items-center gap-3 w-[180px] h-[40px] bg-white py-[16px] px-[15px] rounded-full text-black text-[13px] font-[500]">
                View departments
                <HiArrowLongRight />
              </button>
            </div>

            <div className="w-[50%] flex justify-center items-center flex-col  h-[80%] gap-2 ">
              <div className="flex items-center gap-2 ">
                <img src={DoctorIcon} className="w-[30px] h-[30px]" alt="" />
                <h3 className="font-poppins font-[500] text-lg leading-[25.2px]">
                  All Doctors
                </h3>
              </div>
              <p className="text-[50px] font-semibold leading-[60px]">
                {totalDoctors}
              </p>

              <button className="flex items-center justify-center gap-3 w-[180px] h-[40px] bg-white py-[16px] px-[15px] rounded-full text-black text-[13px] font-[500]">
                View doctors
                <HiArrowLongRight />
              </button>
            </div>
          </div>
        </div>
        <div className="w-[25%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <FiUser className="text-[24px] text-[#47BD68]" />
            <h2 className="text-lg font-[500]">Active Patients </h2>
          </div>

          <p className="font-semibold text-[50px] text-[#47BD68]">
            {totalPatients}
          </p>

          <div className="w-full flex justify-center items-center gap-2">
            <PiChartLineLight className="text-[24px] text-[#47BD68] " />
            <p className="text-grey text-xs font-[500]">
              {Math.round(Math.abs(hospitalAnalytics?.patientIncrease))}% &nbsp;
              this Month
            </p>
          </div>
        </div>
        <div className="w-[25%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <FaRegFolderOpen className="text-[24px] text-[#FF2D55]" />
            <h2 className="text-lg font-[500]">All Transcripts</h2>
          </div>

          <p className="font-semibold text-[50px] text-[#FF2D55]">
            {" "}
            {transcriptionData?.totalTranscriptions}
          </p>
          <div className="w-full flex justify-center items-center gap-2">
            <PiChartLineLight className="text-[24px] text-[#47BD68] " />
            <p className="text-grey text-xs font-[500]">
              {Math.round(Math.abs(transcriptionData?.transcriptionChange))}%
              &nbsp; this Month
            </p>
          </div>
        </div>
      </div>

      <div className="mt-[50px] bg-white w-full  rounded-[20px] p-6 ">
        <div className="flex justify-between items-center ">
          <h3 className="text-lg font-semibold">Transcript Analysis</h3>
        </div>

        <div className="flex items-center gap-5 mt-4 mb-[60px]">
          <div className="flex items-center  gap-2">
            <div className="w-[10px] h-[10px] rounded-full bg-primary"></div>
            <p>Transcriptions</p>
          </div>

          <div className="flex items-center  gap-2">
            <div className="w-[10px] h-[10px] rounded-full bg-secondary"></div>
            <p>Translations</p>
          </div>
        </div>

        <div
          className="chart-container mt-"
          style={{ width: "90%", margin: "auto" }}
        >
          <Line data={data} options={options} />
        </div>
      </div>

      {addDoctorPopup && <AddDoctorContent toggleModal={toggleDoctor} />}
      {addDepartmentPopup && (
        <AddDepartmentModal toggleModal={toggleDepartment} />
      )}
    </HospitalDashboardLayout>
  );
};

export default HospitalDashboard;
