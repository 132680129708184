import React from "react";
import FeatuedImage from "../../../images/landing/blogImage.svg";
import { Link } from "react-router-dom";

const FeatureCard = ({ title, desc, date, img, link }) => {
  return (
    <>
      <div className="px-[50px] mb-5 h-[450px]">
        <div
          className="w-full  flex-col justify-center rounded-[20px] border-b border-[#EFEBEB] "
          style={{ boxShadow: " 0px 0px 10px 0px #00000026" }}
        >
          <a href={link} target="_blank" rel="noreferrer">
            <img
              src={img}
              className="w-full h-[250px] bg-cover rounded-t-[20px]"
              alt=""
            />
          </a>
          <div className="p-[20px] pb-7">
            <a href={link} target="_blank" rel="noreferrer">
              <h2 className=" font-bold text-[20px] leading-[28px] my-2">
                {title}
              </h2>
            </a>

            <div className="flex items-center text-[12px] leading-[22px] text-grey gap-6">
              <p>{date}</p>
              <div className="flex items-center gap-1">
                {/* <div className="w-1 h-1 rounded-full bg-grey"></div> */}
                {/* <p>4min</p> */}
              </div>
            </div>

            <p className="text-grey text-sm leading-[22px] mt-4">{desc}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureCard;
