import React, { useEffect, useState } from "react";
import Zig from "../../../images/landing/zihzag.png";
import { FaArrowDownLong } from "react-icons/fa6";
import MedBot from "../../../images/landing/medbot.svg";
import { CiMicrophoneOn } from "react-icons/ci";
import BASE_URL from "../../../baseUrl";
import axios from "axios";
import RequestDemo from "../RequestDemo";
import { IoIosArrowDown } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";

const Demo = () => {
  const [rawInput, setRawInput] = useState("");
  const [processedData, setProcessedData] = useState("");
  const [rawData, setRawData] = useState("");
  const [messages, setMessages] = useState(() => {
    // Load messages from localStorage on initial render
    const storedMessages =
      JSON.parse(localStorage.getItem("transcriptions")) || [];
    return storedMessages;
  });
  const [isTranslate, setIsTranslate] = useState(false);
  const [isTranslateId, setIsTranslateId] = useState();
  const [isTranscribe, setIsTranscribe] = useState(true);

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedMessages =
      JSON.parse(localStorage.getItem("transcriptions")) || [];
    const storedTrials = parseInt(localStorage.getItem("remainingTrials")) || 5;

    setMessages(storedMessages);
    // setRemainingTrials(storedTrials);
  }, []);

  const language = [
    {
      value: "pa",
      name: "Punjabi ",
    },
    {
      value: "es",
      name: "Spanish",
    },
    // {
    //   value: "pt",
    //   name: "Portuguese (Brazil)",
    // },

    {
      value: "ar",
      name: "Arabic",
    },
    {
      value: "de",
      name: "German",
    },
    {
      value: "hi",
      name: "Hindi",
    },
    {
      value: "sr",
      name: "Serbian",
    },
    {
      value: "ur",
      name: "Urdu",
    },
    // {
    //   value: "fr",
    //   name: "French",
    // },
    {
      value: "so",
      name: "Somali",
    },
    {
      value: "uk",
      name: "Ukrainian",
    },
    {
      value: "zh-TW",
      name: "Chinese (Traditional)",
    },

    // {
    //   value: "am",
    //   name: "Amharic",
    // },
  ];

  const [inlineLanguages, setInlineLanguages] = useState([
    {
      value: "fr",
      name: "French",
    },

    {
      value: "am",
      name: "Amharic",
    },
    {
      value: "pt",
      name: "Portuguese (Brazil)",
    },
  ]); // Initial inline languages
  const [selectedLanguage, setSelectedLanguage] = useState();

  const handleLanguageSelect = (selected, data) => {
    setSelectedLanguage(selected);
    // Update the middle language dynamically
    // setInlineLanguages((prev) => [prev[0], selected, prev[2]]);
    setIsLanguageDropdown(false);
    setIsTranscribe(false);
    setRawInput(data);
  };

  const handleTranscrbe = async () => {
    if (isTranscribe) {
      const data = {
        medTextReport: rawInput,
      };
      setRawData(rawInput);

      const transcriptionCount = messages.filter(
        (msg) => msg.type === "transcription"
      ).length;

      if (transcriptionCount > 5) {
        return toast.error("Transcription limit reached");
      }
      const newMessage = {
        type: "transcription",
        original: rawInput,
        transcribed: "",
        timestamp: new Date().toISOString(), // To maintain order
      };

      const translate = await axios({
        method: "POST",
        url: `${BASE_URL}/transcribe-home`,
        data: data,
      })
        .then((response) => {
          console.log("Transcribe Success", response);

          newMessage.transcribed = response?.data?.report?.transcribe?.replace(
            /\\n/g,
            "\n"
          );

          // Add the new message to the messages array
          const updatedMessages = [...messages, newMessage];

          // Save messages to localStorage
          localStorage.setItem(
            "transcriptions",
            JSON.stringify(updatedMessages)
          );

          // Update state
          setMessages(updatedMessages);

          setRawInput("");
        })
        .catch((error) => {
          console.log("Transcribe  Error", error.response.data);
          // toast.error(error.response.data);
        });
    } else {
      const translationCount = messages.filter(
        (msg) => msg.type === "translation"
      ).length;

      if (translationCount > 5) {
        return toast.error("Translation limit reaches!");
      }
      const newMessage = {
        type: "translation",
        original: rawInput,
        translated: "",
        timestamp: new Date().toISOString(),
      };
      const transcribeData = await axios({
        method: "POST",
        url: `${BASE_URL}/translate-home`,
        data: {
          text: rawInput,
          sourceLanguage: "en",
          targetLanguage: selectedLanguage?.value,
        },
      })
        .then((response) => {
          console.log("Translate Success", response);

          newMessage.translated = response?.data?.translatedText;
          const updatedMessages = [...messages, newMessage];
          localStorage.setItem(
            "transcriptions",
            JSON.stringify(updatedMessages)
          );
          setMessages(updatedMessages);
          setIsTranscribe(true);
          setIsTranslateId();

          setRawInput("");
        })
        .catch((error) => {
          console.log("Transcribe  Error", error.response.data);
        });
    }
  };
  return (
    <>
      <div className="px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px]  pt-[20px] pb-[30px] bg-[#2A65551A] font-poppins " id="requestaDemo">
        <div className="flex w-full max-[1024px]:flex-col  gap-12 ">
          <div className="w-[50%]  max-[1024px]:w-full">
            <div className="flex items-center relative gap-3">
              <h2 className=" text-[20px] max-[750px]:text-[18px] font-[400] leading-[30px] ">
                Have a quick tryout
              </h2>
              <div className="p-[15px] max-[750px]:p-[10px] bg-secondary rounded-full">
                <FaArrowDownLong className="text-white text-[24px] max-[750px]:text-[17px]" />
              </div>
            </div>

            <div
              className="w-full bg-primary  mt-6 rounded-[15px] p-[20px]"
              style={{ boxShadow: "0px 0px 12.4px 0px #00000026" }}
            >
              <div className="w-full flex justify-center ">
                <img src={MedBot} alt="" />
              </div>
              <div className="mt-11 h-[410px] overflow-y-auto ">
                <div className="bg-white  w-[340px] max-[750px]:w-[70%] max-[750px]:p-[10px] h-[49px] rounded-[15px] rounded-bl-none flex items-center justify-center my-4">
                  <p className="text-sm leading-[21px] max-[750px]:text-[13px] text-[#000]">
                    Hi, kindly enter what you would like to simplify
                  </p>
                </div>

                <div className="flex justify-end flex-col ">
                  {messages?.map((data, index) => (
                    <>
                      <div key={index} className="">
                        <div className="bg-[#E1FFF7]  my-4 w-[65%] p-[15px] rounded-[15px] rounded-br-none ">
                          <p className="text-sm leading-[21px] text-[#000]">
                            {data?.original}
                          </p>
                        </div>
                        {data.type === "transcription" && (
                          <div className="w-full flex justify-end">
                            <div className="bg-white  my-4 w-[65%] p-[15px] rounded-[15px] rounded-br-none  ">
                              <p className="text-sm leading-[21px] text-[#000]">
                                {data?.transcribed}
                                <br />

                                {isTranslateId != index && (
                                  <button
                                    className="flex items-center text-sm font-semibold px-3 py-[6px] mt-1 bg-primary text-white gap-1 rounded-full"
                                    onClick={() => {
                                      setIsTranslate(true);
                                      setIsTranslateId(index);
                                    }}
                                  >
                                    Translate{" "}
                                    <FaArrowDownLong className="-rotate-90" />
                                  </button>
                                )}

                                {isTranslate && isTranslateId == index && (
                                  <>
                                    <div className="flex items-center gap-3 mt-2 ">
                                      <div className="flex items-center gap-3">
                                        {inlineLanguages?.map((lang, index) => (
                                          <div
                                            key={lang.value}
                                            className={`text-xs px-1 border-r border-black cursor-pointer ${
                                              selectedLanguage?.value ==
                                              lang?.value
                                                ? "text-primary font-[500]"
                                                : "text-black"
                                            } `}
                                            onClick={() =>
                                              handleLanguageSelect(
                                                lang,
                                                data?.transcribed
                                              )
                                            }
                                          >
                                            {lang.name}
                                          </div>
                                        ))}
                                      </div>

                                      <div className="relative">
                                        <div
                                          className="flex items-center cursor-pointer "
                                          onClick={() =>
                                            setIsLanguageDropdown(
                                              !isLanguageDropdown
                                            )
                                          }
                                        >
                                          {selectedLanguage &&
                                          selectedLanguage?.value !== "fr" &&
                                          selectedLanguage?.value !== "pt" &&
                                          selectedLanguage?.value !== "am" ? (
                                            <>
                                              <p
                                                className={`text-xs  text-primary font-[500]`}
                                              >
                                                MORE
                                              </p>
                                              <IoIosArrowDown className="text-primary font-[500]" />
                                            </>
                                          ) : (
                                            <>
                                              <p
                                                className={`text-xs  text-black`}
                                              >
                                                MORE
                                              </p>
                                              <IoIosArrowDown />
                                            </>
                                          )}
                                        </div>

                                        {isLanguageDropdown && (
                                          <>
                                            <div className="absolute top-[31px] bg-white w-[150px] right-[1px] p-[20px]">
                                              {language?.map((data1, index) => (
                                                <div
                                                  className="py-2 cursor-pointer"
                                                  onClick={() =>
                                                    handleLanguageSelect(
                                                      data1,
                                                      data?.transcribed
                                                    )
                                                  }
                                                >
                                                  <p>{data1?.name}</p>
                                                </div>
                                              ))}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        )}

                        {data.type === "translation" && (
                          <div className="w-full flex justify-end">
                            <div className="bg-white  my-4 w-[65%] p-[15px] rounded-[15px] rounded-br-none  ">
                              <p className="text-sm leading-[21px] text-[#000]">
                                {data?.translated}
                                <br />
                                {/* 
                                {isTranslateId != index && (
                                  <button
                                    className="flex items-center text-sm font-semibold px-3 py-[6px] mt-1 bg-primary text-white gap-1 rounded-full"
                                    onClick={() => {
                                      setIsTranslate(true);
                                      setIsTranslateId(index);
                                    }}
                                  >
                                    Translate{" "}
                                    <FaArrowDownLong className="-rotate-90" />
                                  </button>
                                )}

                                {isTranslate && isTranslateId == index && (
                                  <>
                                    <div className="flex items-center gap-3 mt-2 ">
                                      <div className="flex items-center gap-3">
                                        {inlineLanguages?.map((lang, index) => (
                                          <div
                                            key={lang.value}
                                            className={`text-xs px-1 border-r border-black ${
                                              lang.value !==
                                              selectedLanguage.value
                                                ? "text-black"
                                                : " text-primary font-[500]"
                                            }`}
                                          >
                                            {lang.name}
                                          </div>
                                        ))}
                                      </div>

                                      <div className="relative">
                                        <div
                                          className="flex items-center cursor-pointer "
                                          onClick={() =>
                                            setIsLanguageDropdown(
                                              !isLanguageDropdown
                                            )
                                          }
                                        >
                                          <p className="text-xs  text-black font-normal ">
                                            MORE
                                          </p>
                                          <IoIosArrowDown />
                                        </div>

                                        {isLanguageDropdown && (
                                          <>
                                            <div className="absolute top-[31px] bg-white w-[150px] right-[1px] p-[20px]">
                                              {language?.map((data1, index) => (
                                                <div
                                                  className="py-2 cursor-pointer"
                                                  onClick={() =>
                                                    handleLanguageSelect(
                                                      data1,
                                                      data?.transcribed
                                                    )
                                                  }
                                                >
                                                  <p>{data1?.name}</p>
                                                </div>
                                              ))}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )} */}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                  {/* {processedData && (
                    <div className="bg-[#E1FFF7] my-4 w-[60%] p-[15px] rounded-[15px] rounded-br-none ">
                      <p className="text-sm leading-[21px] text-[#000]">
                        {rawData}
                      </p>
                    </div>
                  )} */}
                </div>
              </div>

              <div
                className="h-[80px] w-full rounded-[15px] border border-[#B0B0B0] mt-7 bg-white p-[10px] flex justify-between items-center gap-6"
                style={{ boxShadow: "0px 0px 12.5px 0px #00000026" }}
              >
                <textarea
                  name=""
                  id=""
                  className="outline-none w-full max-h-[60px]"
                  placeholder="Type something"
                  value={rawInput}
                  onChange={(e) => setRawInput(e.target.value)}
                ></textarea>

                <div className="flex items-center gap-2">
                  <div
                    className="w-[35px] h-[35px] rounded-full bg-primary flex items-center justify-center cursor-pointer"
                    onClick={handleTranscrbe}
                  >
                    <FiSend className="text-white" size={22} />
                  </div>
                  {/* <button >Send</button> */}
                  {/* <div className="w-[35px] h-[35px] rounded-full bg-primary flex items-center justify-center">
                    <CiMicrophoneOn className="text-white" size={22} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="w-[50%]  max-[1024px]:w-full">
            <RequestDemo />
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
