import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "react-awesome-slider/dist/styles.css";
import FallBack from "./Pages/FallBack";
import DoctorDashboardHome from "./Pages/doctor/DoctorDashboardHome";
import Patients from "./Pages/doctor/Patients";
import General from "./Pages/doctor/Setting/General";
import Prefrences from "./Pages/doctor/Setting/Prefrences";
import Notifications from "./Pages/doctor/Setting/Notifications";
import Appearances from "./Pages/doctor/Setting/Appearances";
import Resources from "./Pages/doctor/support/Resources";
import Privacy from "./Pages/doctor/support/Privacy";
import ContactAdmin from "./Pages/doctor/support/ContactAdmin";
import AddRecord from "./Pages/doctor/AddRecord";
import Transcribe from "./Pages/doctor/Transcribe";
import ThemeSwitcher from "./Storage/ThemeSwitcher";
import PatientDetail from "./Pages/doctor/PatientDetail";
import Login from "./Pages/hospital/Login";
import PublicLogin from "./Pages/Login";
import PublicRegister from "./Pages/SignUp";

import HospitalDashboardHome from "./Pages/hospital/HospitalDashboardHome";
import Doctors from "./Pages/hospital/Doctors";
// import  {DoctorDetail} from "./Pages/hospital/DoctorDetail";
import { Departments } from "./Pages/hospital/Departments";
import { DepartmentProfile } from "./Pages/hospital/DepartmentProfile";
import Subscriptions from "./Pages/hospital/setting/Subscriptions";
import HNoty from "./Pages/hospital/setting/Notifications";
import HAppearances from "./Pages/hospital/setting/Appearances";
import HcontachAdmin from "./Pages/hospital/support/ContactAdmin";
import Hprivacy from "./Pages/hospital/support/Privacy";
import Hresource from "./Pages/hospital/support/Resources";
import NewPassword from "./Pages/hospital/NewPassword";
import PasswordSuccess from "./Pages/hospital/PasswordSuccess";
import Onboarding from "./Pages/hospital/Onboarding";
import Analytics from "./Pages/hospital/Analytics";
import ManageSubscription from "./Pages/hospital/setting/ManageSubscription";
import SuperAdminLogin from "./Pages/superadmin/Login";
import SuperDashboard from "./Pages/superadmin/SuperDashboard";
import SuperHospital from "./Pages/superadmin/SuperHospital";
import SuperHospitlProfile from "./Pages/superadmin/SuperHospitlProfile";
import ManageSubscriptionSuper from "./Pages/superadmin/ManageSubscriptionSuper";
import SuperAnalytics from "./Pages/superadmin/SuperAnalytics";
import SuperSubcriptions from "./Pages/superadmin/setting/SuperSubcriptions";
import SuperNotifications from "./Pages/superadmin/setting/SuperNotifications";
import SuperAppearances from "./Pages/superadmin/setting/SuperAppearances";
import PatientHome from "./Pages/patient/PatientHome";
import Signup from "./Pages/patient/Signup";
import Signin from "./Pages/patient/Signin";
import HomeDashboardP from "./Pages/patient/dashboard/HomeDashboardP";
import PateientReport from "./Pages/patient/dashboard/PateientReport";
import ReportDetail from "./Pages/patient/dashboard/ReportDetail";
import PatientHospital from "./Pages/patient/dashboard/PatientHospital";
import PatientSetting from "./Pages/patient/dashboard/PatientSetting";
import PatientSupport from "./Pages/patient/dashboard/PatientSupport";
import DoctorLogin from "./Pages/doctor/DoctorLogin";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "reactjs-popup/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import { getCookie, isAuth } from "./auth/helper";
import PasswordSetup from "./Component/Hospital/PasswordSetup";
import DoctorPasswordSetup from "./Component/Doctor/DoctorPasswordSetUp";
import DoctorNewPassword from "./Pages/doctor/DoctorNewPassword";
import DoctorDashboardNew from "./Pages/doctor/DoctorDashboardNew";
import PatientNewPaswsword from "./Pages/patient/PatientNewPassword";
import PatientPricing from "./Pages/patient/PatientPricing";
import { useContext, useEffect } from "react";
import axios from "axios";
import BASE_URL from "./baseUrl";
import GlobalStorage from "./Storage/ContextProvider";
import PaymentSuccess from "./Pages/patient/PaymentSuccess";
import HospitalPricingPage from "./Pages/patient/HospitalPricingPage";
import PatientForgetPassword from "./Pages/patient/PatientForgetPassword";
import PatientResetPassword from "./Pages/patient/PatientResetPassword";
import HospitalPrivateRoute from "./auth/HospitalPrivateRoute";
import DoctorPrivateRoute from "./auth/DoctorPrivateRoute";
import PatientContactus from "./Pages/patient/PatientContactus";
import PrivacyPolicy from "./Pages/patient/PrivacyPolicy";
import TermsOfUse from "./Pages/patient/TermsOfUse";
import PatientPrivateRoute from "./auth/PatientPrivateRoute";
import SuperAdminPrivateRoute from "./auth/SuperAdminPrivateRoute";
import * as jwt_decode from "jwt-decode";
import cookie from "js-cookie";
import PasswordDone from "./Pages/patient/PasswordDone";
import TokenExpired from "./Pages/patient/TokenExpired";
import DoctorPasswordDone from "./Pages/doctor/DoctorPasswordDone";
import HospitalPasswordDone from "./Pages/hospital/HospitalPasswordDone";
import DoctorTokenExpired from "./Pages/doctor/DoctorTokenExpired";
import HospitalTokenExpired from "./Pages/hospital/HospitalTokenExpired";
import { DoctorDetailProfile } from "./Pages/hospital/DoctorDetailProfile";
import DoctorForgetPassword from "./Pages/doctor/DoctorForgetPassword";
import DoctortResetPassword from "./Pages/doctor/DoctorResetPassword";
import ExpireDoctorToken from "./Pages/doctor/ExpireDoctorToken";
import PatientExpireToken from "./Pages/patient/PatientExpireToken";
import HospitalForgotPassword from "./Pages/hospital/HospitalForgotPassword";
import HospitalResetPassword from "./Pages/hospital/HospitalResetPassword";
import ExpireHospitalToken from "./Pages/hospital/ExpireHospitalToken";
import TwofaVerify from "./Pages/patient/TwofaVerify";
import Dashboard from "./v2/pages/doctor/dashboard";
import PatientsV2 from "./v2/pages/doctor/patients";
import PatientDashboard from "./v2/pages/patient/dashboard/Dashboard";
import Patientv2Report from "./v2/pages/patient/reports/index";

import PatientDetailsV2 from "./v2/pages/doctor/patient-details";
import SettingsV2 from "./v2/pages/doctor/settings";
import DocumentView from "./Pages/doctor/DocumentView";
import PatientReportDetail from "./v2/pages/patient/reports/reportDetail";
//import { io } from "socket.io-client";
// import DoctorDetail from "./Pages/hospital/DoctorDetail";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Pricing from "./Pages/Pricing";
import Faqs from "./Pages/Faqs";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import Simplify from "./v2/pages/patient/simplify/Simplify";
import HelpandSupport from "./v2/pages/doctor/help";
import Setting from "./v2/pages/patient/setting/Setting";
import ContactV2 from "./v2/pages/doctor/contact";
import PrivacyV2 from "./v2/pages/doctor/privacy";
import HospitalDashboard from "./v2/pages/hospital/dashboard/HospitalDashboard";
import HospitalDepartments from "./v2/pages/hospital/departments/HospitalDepartments";
import DepartmentDetail from "./v2/pages/hospital/departments/DepartmentDetail";
import DoctorV2 from "./v2/pages/hospital/doctors";
import DoctorDetail from "./v2/pages/hospital/DoctorDetail";
import HospitalSetting from "./v2/pages/hospital/setting/HospitalSetting";
import HospitalHelp from "./v2/pages/hospital/HospitalHelp";
import HospitalPrivacy from "./v2/pages/hospital/HospitalPrivacy";
import HospitalContact from "./v2/pages/hospital/HospitalContact";
import DoctorPassword from "./Pages/hospital/DoctorPassword";

function App() {
  // const socket = io();
  // const token = getCookie("token");
  // const { hospitalProfile, c } = useContext(GlobalStorage);
  // useEffect(() => {
  //   socket.on("connect", () => {
  //     socket.emit("joinRoom", token);
  //     console.log("Connected");
  //   });
  // }, []);

  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <ThemeSwitcher />
        <Routes>
          {/* <Route path="/" element={<FallBack />} /> */}
          {/* <Route
            path="/doctor"
            element={
              <DoctorRoute>
                <DoctorDashboardHome />
              </DoctorRoute>
            }
          /> */}
          <Route path="/login" element={<PublicLogin />} />
          <Route path="/signup" element={<PublicRegister />} />

          <Route
            path="/v2/hospital/dashboard"
            element={<HospitalDashboard />}
          />
          <Route
            path="/v2/hospital/departments"
            element={<HospitalDepartments />}
          />
          <Route
            path="/v2/hospital/department/:id"
            element={<DepartmentDetail />}
          />
          <Route path="/v2/hospital/doctors" element={<DoctorV2 />} />
          <Route path="/v2/hospital/doctors/:id" element={<DoctorDetail />} />
          <Route path="/v2/hospital/settings" element={<HospitalSetting />} />
          <Route path="/v2/hospital/resources" element={<HospitalHelp />} />
          <Route path="/v2/hospital/privacy" element={<HospitalPrivacy />} />
          <Route path="/v2/hospital/contact" element={<HospitalContact />} />

          <Route
            path="/doctor/setPassword/:token"
            element={<DoctorPassword />}
          />
          <Route path="/v2/doctor/dashboard" element={<Dashboard />} />
          <Route path="/v2/doctor/patients" element={<PatientsV2 />} />
          <Route path="/v2/doctor/settings" element={<SettingsV2 />} />
          <Route
            path="/v2/doctor/patients/:id"
            element={<PatientDetailsV2 />}
          />
          <Route path="/v2/doctor/resources" element={<HelpandSupport />} />
          <Route path="/v2/doctor/privacy" element={<PrivacyV2 />} />
          <Route path="/v2/doctor/contact" element={<ContactV2 />} />

          <Route path="/v2/patient/dashboard" element={<PatientDashboard />} />
          <Route path="/v2/patient/reports" element={<Patientv2Report />} />
          <Route path="/v2/patient/settings" element={<Setting />} />
          <Route
            path="/v2/patient/report/:id"
            element={<PatientReportDetail />}
          />
          <Route path="/v2/patient/dashboard/simplify" element={<Simplify />} />

          {/* <Route element={<PatientPrivateRoute />}>
            <Route path="/patient/dashboard" element={<HomeDashboardP />} />
            <Route path="/dashboard/myreport" element={<PateientReport />} />
            <Route path="/dashboard/myreport/:id" element={<ReportDetail />} />
          </Route>
          <Route element={<DoctorPrivateRoute />}>
            <Route path="/doctor/dashboard" element={<DoctorDashboardNew />} />
            <Route path="/doctor/doc-view" element={<DocumentView />} />
            <Route path="/doctor/patients" element={<Patients />} />
            <Route path="/doctor/patient/:patientId" element={<AddRecord />} />
            <Route
              path="/doctor/transcribe/:patientId"
              element={<Transcribe />}
            />
            <Route
              path="/doctor/report/:reportId"
              element={<PatientDetail />}
            />
          </Route> */}
          {/* 
          <Route
            path="/doctor/forget-password"
            element={<DoctorForgetPassword />}
          />
          <Route
            path="/doctor/resetPassword/:token"
            element={<DoctortResetPassword />}
          />
          <Route path="/doctor/expire-token" element={<ExpireDoctorToken />} />
          <Route
            path="/doctor/setPassword/:token"
            element={<DoctorNewPassword />}
          />
          <Route
            path="/doctor/password-done"
            element={<DoctorPasswordDone />}
          />
          <Route
            path="/doctor/token-expired/:doctorId"
            element={<DoctorTokenExpired />}
          />
          <Route path="/doctor/setting/general" element={<General />} />
          <Route path="/doctor/setting/preferences" element={<Prefrences />} />
          <Route
            path="/doctor/setting/notifications"
            element={<Notifications />} */}
          {/* /> */}
          {/* <Route path="/doctor/setting/appearances" element={<Appearances />} /> */}
          {/* <Route path="/doctor/support/resources" element={<Resources />} />
          <Route path="/doctor/support/privacy" element={<Privacy />} />
          <Route path="/doctor/support/contact" element={<ContactAdmin />} />
          <Route path="/doctor/login" element={<DoctorLogin />} />
          <Route path="/hospital/login" element={<Login />} />
          <Route path="/setPassword/:token" element={<PasswordSetup />} />
          <Route element={<HospitalPrivateRoute />}>
            <Route path="/hospital" element={<HospitalDashboardHome />} />
          </Route> */}

          {/* <HospitalPrivateRoute
            path="/hospital/home"
            element={<HospitalDashboardHome />}
          /> */}
          {/* <Route path="/hospital/doctors" element={<Doctors />} />
          <Route
            path="/hospital/forget-password"
            element={<HospitalForgotPassword />}
          />
          <Route
            path="/hospital/resetPassword/:token"
            element={<HospitalResetPassword />}
          />
          <Route
            path="/hospital/expire-token"
            element={<ExpireHospitalToken />}
          />

          <Route
            path="/hospital/doctor/:doctorId"
            element={<DoctorDetailProfile />}
          />
          <Route path="/hospital/departments" element={<Departments />} />
          <Route
            path="/hospital/department/:departmentId"
            element={<DepartmentProfile />}
          />
          <Route
            path="/hospital/setting/subscription"
            element={<Subscriptions />}
          />
          <Route
            path="/hospital/setting/manage-subscription"
            element={<ManageSubscription />}
          />
          <Route path="/hospital/setting/notifications" element={<HNoty />} />
          <Route
            path="/hospital/setting/appearances"
            element={<HAppearances />}
          />
          <Route path="/hospital/support/resources" element={<Hresource />} />
          <Route path="/hospital/support/privacy" element={<Hprivacy />} />
          <Route path="/hospital/support/contact" element={<HcontachAdmin />} />
          <Route
            path="/hospital/new-password/:token"
            element={<NewPassword />}
          />
          <Route
            path="/hospital/token-expired/:hospitalId"
            element={<HospitalTokenExpired />}
          />
          <Route
            path="/hospital/password-done"
            element={<HospitalPasswordDone />}
          />

          <Route
            path="/hospital/password-success"
            element={<PasswordSuccess />}
          />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/hospital/onboarding" element={<Onboarding />} />
          <Route path="/hospital/analytics" element={<Analytics />} />

          <Route
            path="/superadmin/hospital/:id"
            element={<SuperHospitlProfile />}
          /> */}

          {/* <Route path="/superadmin/login" element={<SuperAdminLogin />} />
          <Route element={<SuperAdminPrivateRoute />}>
            <Route path="/superadmin" element={<SuperDashboard />} />
            <Route path="/superadmin/hospitals" element={<SuperHospital />} />
            <Route
              path="/superadmin/manage-subscription"
              element={<ManageSubscriptionSuper />}
            />
          </Route>
          <Route path="/superadmin/analytics" element={<SuperAnalytics />} />
          <Route
            path="/superadmin/setting/subscription"
            element={<SuperSubcriptions />}
          />
          <Route
            path="/superadmin/setting/notifications"
            element={<SuperNotifications />}
          />
          <Route
            path="/superadmin/setting/appearances"
            element={<SuperAppearances />}
          /> */}

          <Route path="/" element={<PatientHome />} />
          <Route path="/pricing" element={<Pricing />} />
          {/* <Route
            path="/patient/pricing/:hid"
            element={<HospitalPricingPage />}
          />

          <Route path="/patient/signup" element={<Signup />} />
          <Route path="/patient/signin" element={<Signin />} />
          <Route path="/patient/2fa-verify" element={<TwofaVerify />} />

          <Route path="/contact-us" element={<PatientContactus />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/resources/faqs" element={<Faqs />} />
          <Route path="/resources/blogs" element={<Blog />} />
          <Route path="/resources/blog/:id" element={<BlogDetail />} />

          <Route path="/about" element={<About />} />

          {/* <Route
            path="/patient/forget-password"
            element={<PatientForgetPassword />}
          />
          <Route path="/patient/password-done" element={<PasswordDone />} />
          <Route
            path="/patient/token-expired/:patientId"
            element={<TokenExpired />}
          />
          <Route
            path="/patient/expire-token"
            element={<PatientExpireToken />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route
            path="/patient/resetPassword/:token"
            element={<PatientResetPassword />}
          />

          <Route
            path="/patient/setPassword/:token"
            element={<PatientNewPaswsword />}
          /> */}

          {/* <Route path="/dashboard/myreport/detail" element={<ReportDetail />} /> */}
          {/* <Route path="/dashboard/hospital" element={<PatientHospital />} />
          <Route path="/dashboard/setting" element={<PatientSetting />} />
          <Route path="/dashboard/support" element={<PatientSupport />} /> */}
          {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
