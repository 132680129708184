import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { isAuth } from "../../../auth/helper";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import GlobalStorage from "../../../Storage/ContextProvider";

const AddDepartmentModal = ({ toggleModal }) => {
  const { refreshDepartment, setRefreshDepartment } = useContext(GlobalStorage);
  const [values, setValues] = useState({
    assignedAccount: "",
    departmentId: "",
    departmentName: "",
  });

  const { assignedAccount, departmentId, departmentName } = values;

  const handleAddDepartment = async (e) => {
    e.preventDefault();
    if (!assignedAccount) {
      return toast.error("Add Assign Account");
    }
    const data = {
      assignedAccount,
      departmentId,
      departmentName,
      hospital: isAuth()?._id,
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/addDepartment`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        toast.success(response?.data?.msg);
        setValues({
          ...values,
          assignedAccount: "",
          departmentId: "",
          departmentCode: "",
          departmentName: "",
        });
        setRefreshDepartment(!refreshDepartment);
        toggleModal()
        // setCreateDepartment(false);
        // navigate("/superadmin");

        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Department Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  const handleChange = (name) => (event) => {
    // console.log(event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <>
      <div
        //   onClick={toggleModal}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div className="bg-white w-full max-w-md rounded-[20px] shadow-lg p-8">
          <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">
            New Department
          </h2>

          {/* Form */}
          <form className="space-y-4">
            {/* First Name */}
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                Department Name
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter first name"
                onChange={handleChange("departmentName")}
                value={departmentName}
              />
            </div>

            {/* Patient Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Department ID
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter Department ID"
                onChange={handleChange("departmentId")}
                value={departmentId}
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Assigned Account
              </label>
              <input
                type="number"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter Assigned Account"
                onChange={handleChange("assignedAccount")}
                value={assignedAccount}
              />
            </div>

            {/* Submit Button */}
            <div className="flex flex-col justify-end space-x-4">
              <button
                className="px-4 py-2 text-white bg-primary   rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handleAddDepartment}
              >
                Add Department
              </button>
              <button
                type="button"
                onClick={toggleModal}
                className="px-4 py-2 text-gray-600    rounded-md focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddDepartmentModal;
