import React from 'react'
import HospitalDashboardLayout from '../../layout/hospital';
import BreadCrumb from '../../component/breadcrumb';

const HospitalContact = () => {
    const helpTopics = [
        {
          id: 1,
          title: "Getting Started ",
          time: "5 min",
          banner: require("../../../images/help-resource.png"),
        },
        {
          id: 2,
          title: "Managing Your ",
          time: "3 minutes",
          banner: require("../../../images/help-resource.png"),
        },
        {
          id: 3,
          title: "Understanding Analytics",
          time: "7 minutes",
          banner: require("../../../images/help-resource.png"),
        },
        {
          id: 4,
          title: "Setting Up Notifications",
          time: "4 minutes",
          banner: require("../../../images/help-resource.png"),
        },
      ];
    
      return (
        <HospitalDashboardLayout>
          <BreadCrumb parent={"Help and Support"} page={"Contact Us"} />
    
          <div className="flex flex-col  min-h-screen ">
            <div className="w-full max-w-4xl p-6">
              <span className="text-gray-600 mb-8">
                Let us know how we can help. We typically respond within 24 - 48
                hours.
              </span>
              <hr className="border border-[#D7D7D7] mt-3" />
    
              <form className="grid grid-cols-1 pt-10 gap-6 md:grid-cols-2">
                {/* First Name */}
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="John"
                  />
                </div>
    
                {/* Last Name */}
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Doe"
                  />
                </div>
    
                {/* Work Email */}
                <div className="col-span-1 md:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Work Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="you@example.com"
                  />
                </div>
    
                {/* Subject */}
                <div className="col-span-1 md:col-span-2">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Subject of your message"
                  />
                </div>
    
                {/* Message */}
                <div className="col-span-1 md:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Type your message here..."
                  ></textarea>
                </div>
    
                {/* Submit Button */}
                <div className="col-span-1 md:col-span-2">
                  <button
                    type="submit"
                    className="px-6 py-2 text-white bg-primary hover:bg-blue-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </HospitalDashboardLayout>
      );
}

export default HospitalContact