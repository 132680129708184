import React, { useContext, useState } from "react";
import HomeHeader from "../Component/HomeHeader";
import GlobalStorage from "../Storage/ContextProvider";
import LoginSvg from "../images/loginSvg.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../baseUrl";
import { toast } from "react-toastify";
import { authenticate } from "../auth/helper";

const Login = () => {
  const { activeMobileNav } = useContext(GlobalStorage);
  const [isProviderActive, setIsProviderActive] = useState(true);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleHospitalLogin = async () => {
    // const {password, confirmPassword}
    setLoading(true);
    const data = {
      email,
      password,
    };
    if (isProviderActive === false) {
      const login = await axios({
        method: "POST",
        url: `${BASE_URL}/hospital/login`,
        data: data,
      })
        .then((response) => {
          console.log("Login Success", response);
          toast.success(response?.data?.message);
          authenticate(response, () => {
            setEmail("");
            setPassword("");
          });
          setLoading(false);
          navigate("/v2/hospital/dashboard");
        })
        .catch((error) => {
          setLoading(false);
          console.log("Login  Error", error?.response?.data);
          toast.error(error?.response?.data);
        });
    }
  };
  return (
    <>
      <HomeHeader activeLink="login" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className="h-[100vh] w-full flex justify-center items-center font-poppins relative">
          <div
            className="w-[550px] h-[640px] py-[31px] px-[29px] rounded-[20px]"
            style={{
              boxShadow: "0px 0px 25px 0px #00000026",
            }}
          >
            <h4 className="text-[36px] leading-[68px] font-bold text-center">
              LOGIN
            </h4>

            <div className="flex items-center justify-between border-[#D7D7D7] border py-[10px] w-full mt-5 px-12">
              <h3
                className={`text-[22px] max-[750px]:text-lg leading-[33px] cursor-pointer ${
                  isProviderActive
                    ? "underline font-bold text-secondary"
                    : "font-normal"
                }`}
                onClick={() => setIsProviderActive(true)}
              >
                For Providers
              </h3>

              <h3
                className={`text-[22px] max-[750px]:text-lg leading-[33px] cursor-pointer ${
                  !isProviderActive
                    ? "underline font-bold text-secondary"
                    : "font-normal"
                }`}
                onClick={() => setIsProviderActive(false)}
              >
                For Hospitals
              </h3>
            </div>

            <div className="mt-[60px]">
              <div className="my-8">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Email Address
                </p>
                <input
                  type="text"
                  className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                  placeholder="johndoe@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-8">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Password
                </p>
                <input
                  type="text"
                  className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="flex justify-end w-full">
                <p className="txt-[20px] leading-[30px] font-[500] text-[#FF9500]">
                  Forgot Password?
                </p>
              </div>

              <button
                className="w-full h-[50px] bg-primary text-white mt-9 rounded-[10px]"
                onClick={handleHospitalLogin}
              >
                Proceed
              </button>
              <Link
                to="/signup"
                className="w-full h-[50px] underline text-center flex justify-center items-center"
              >
                Create an account
              </Link>
            </div>
          </div>

          <img src={LoginSvg} className="absolute bottom-0 right-0" alt="" />
        </div>
      </div>
    </>
  );
};

export default Login;
