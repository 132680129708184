import React, { useContext, useEffect, useRef } from "react";
import LanguageIcon from "../../../images/landing/features/language-square.svg";
import NoteIcon from "../../../images/landing/features/note.svg";
import DatabaseIcon from "../../../images/landing/features/database-01.svg";
import Slider from "react-slick";
import SliderImg from "../../../images/landing/Subtract.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import Article1Img from "../../../images/article/article1.avif";
import Article2Img from "../../../images/article/article2.webp";
import Article3Img from "../../../images/article/article3.avif";
import Articl4Img from "../../../images/article/article4.avif";
import Article5Img from "../../../images/article/article5.avif";
import Article6Img from "../../../images/article/article6.png";
import GlobalStorage from "../../../Storage/ContextProvider";
import FeatureCard from "./FeatureCard";
import { useNavigate } from "react-router-dom";

const FeatureMain = () => {
  const { toggleProduct, setToggleProduct } = useContext(GlobalStorage);

  const navigate = useNavigate();
  const featureRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 full slides in the center
    slidesToScroll: 1,
    centerMode: true, // Enable center mode
    centerPadding: "15%", // Adjust this to control the visibility of the half slides
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000, // Scroll every 3 seconds
    cssEase: "linear", // Smooth scrolling effect
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 1, // Show 1 full slide
          centerPadding: "25%", // More padding for smaller screens
        },
      },
      {
        breakpoint: 600, // Mobile view
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
        },
      },
    ],
  };

  const items = [
    {
      id: 1,
      title: "Bridging healthcare gaps with innovation and accessibility ",
      date: "28/02/2024",
      time: "4min",
      desc: "Working as a communicator in a health care setting, Seun Adetunji could see the impact of medical ... ",
      img: Article1Img,
      link: "https://uwaterloo.ca/conrad-school-entrepreneurship-business/news/bridging-healthcare-gaps-innovation-and-accessibility",
    },
    {
      id: 2,
      title:
        "Waterloo student demystifies medical information with help from AI",
      date: "24/05/2023",
      time: "4min",
      desc: "MedInclude is a new web platform which makes medical information more accessible for patient...",
      img: Article5Img,
      link: "https://uwaterloo.ca/news/media/waterloo-student-demystifies-medical-information-help-ai",
    },
    {
      id: 3,
      title:
        "MedInclude partners with Grand River Hospital to improve patient communication with AI",
      date: "03/12/2023",
      time: "4min",
      desc: "A Velocity health-tech startup focused on improving ...",
      img: Article3Img,
      link: "https://uwaterloo.ca/news/medinclude-partners-grand-river-hospital-improve-patient",
    },
    {
      id: 4,
      title: "Fixing a patient care communication gap",
      date: "22/01/2024",
      time: "4min",
      desc: "Seun Adetunji combines her experience working in the health care sector with her expertise in  ...",
      img: Articl4Img,
      link: "https://uwaterloo.ca/news/global-futures/fixing-patient-care-communication-gap",
    },
    {
      id: 5,
      title: "Med-tech startups injecting life into Kitchener neighbourhood",
      date: "23/12/2024",
      time: "4min",
      desc: "From her desk in the Innovation Arena in downtown Kitchener, Seun Adetunji is helping doctors..",
      img: Article2Img,
      link: "https://www.therecord.com/news/waterloo-region/med-tech-startups-injecting-life-into-kitchener-neighbourhood/article_0a49061d-342f-5c42-af1f-d788f1c8f2b4.html",
    },
    {
      id: 6,
      title:
        "MedInclude tests medical communication simplifying platform at Grand River Hospital",
      date: "19/11/2024",
      time: "4min",
      desc: "When Seun Adetunji founded MedInclude, it was...",
      img: Article6Img,
      link: "https://communitech.ca/technews/medinclude-tests-medical-communication-simplifying-platform-at-grand-river-hospital.html",
    },
  ];

  useEffect(() => {
    if (window.location.hash === "#sectionRef") {
      featureRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // Add more conditions for other sections if needed
  }, [toggleProduct]);

  return (
    <>
      <div
        className=" py-[60px] font-poppins bg-[#2A65551A] overflow-x-hidden" id="featuredOn"
        ref={featureRef}
      >
        <div className="">
          <h2 className="text-center text-[30px] leading-[45px] text-black font-semibold mb-8">
            FEATURED ON
          </h2>
          {/* <FeatureCard /> */}
          <div className="w-full">
            <Slider {...settings}>
              {items?.map((item, index) => (
                <>
                  <FeatureCard
                    title={item.title}
                    desc={item?.desc}
                    date={item?.date}
                    img={item?.img}
                    link={item?.link}
                  />
                </>
              ))}
              {/*            
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard /> */}
            </Slider>
          </div>

          {/* <div className="w-full flex justify-between items-center mt-8">
            <FeatureCard
              cardIcon={LanguageIcon}
              heading="Multilingual Support"
              text="Our health resources are available in multiple languages, ensuring everyone can access clear and accurate information regardless of their linguistic background."
            />

            <FeatureCard
              cardIcon={NoteIcon}
              heading="Real-time Simplification"
              text="By simplifying complex health information in real-time, our platform ensures users can easily understand and act on their health needs immediately"
            />

            <FeatureCard
              cardIcon={NoteIcon}
              heading="Real-time Simplification"
              text="By simplifying complex health information in real-time, our platform ensures users can easily understand and act on their health needs immediately"
            />

            <FeatureCard
              cardIcon={DatabaseIcon}
              heading="Easy, Seamless Sharing"
              text="Easily share simplified and/or translated medical information in a HIPAA-compliant, seamless patient portal or via direct print."
            />
          </div> */}
        </div>

        {/* <div className="my-16">
          <Slider {...settings}>
            <div style={{ width: "98%" }}>
              <img
                src={SliderImg}
                alt="Second slide"
                style={{ width: "100%", borderRadius: "10px" }}
              />
             
            </div>
            <div style={{ width: "2%" }}>
              <img
                src={SliderImg}
                alt="Second slide"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            
          </Slider>
        </div> */}
      </div>
    </>
  );
};

export default FeatureMain;
