import React, { useContext, useEffect, useState } from "react";
import HomeHeader from "../Component/HomeHeader";
import GlobalStorage from "../Storage/ContextProvider";
import LoginSvg from "../images/loginSvg.svg";
import { FaAngleDown } from "react-icons/fa6";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../baseUrl";
import { RxCross2 } from "react-icons/rx";

const SignUp = () => {
  const { activeMobileNav } = useContext(GlobalStorage);
  const [isProviderActive, setIsProviderActive] = useState(true);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [countries, setCoutries] = useState();
  const [states, setState] = useState([]);
  const [countryName, setCountryName] = useState("default");
  const [stateName, setStateName] = useState("default");
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [hospitalPassword, setHospitalPassword] = useState("");

  useEffect(() => {
    if (countryName == "") {
      setCountryName("default");
    }
    loadCountry();
  }, [countryName]);

  const loadCountry = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${countryName}`,
    })
      .then((response) => {
        console.log("Get Coutries", response);
        // setHospitals(response.data);
        setCoutries(response.data);
      })
      .catch((error) => {
        console.log("Get Hospitals ERROR", error.response);
      });
  };

  useEffect(() => {
    if (stateName == "") {
      setStateName("default");
    }
    loadState();
  }, [stateName, selectedCountry]);

  const loadState = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${selectedCountry}&state=${stateName}`,
    })
      .then((response) => {
        console.log("Get States", response);
        // setHospitals(response.data);
        setState(response.data);
      })
      .catch((error) => {
        console.log("Get State ERROR", error.response);
      });
  };
  const handleSelectCountry = (data) => {
    setSelectedCountry(data);
    // setCountryName(data)
    setIsCountryOpen(false);
  };
  const handleSelectState = (data) => {
    setSelectedState(data);
    // setCountryName(data)
    setIsStateOpen(false);
  };
  const handleCountryFocus = () => {
    setIsCountryOpen(true);
    setSelectedCountry();
  };
  const handleStateFocus = () => {
    setIsStateOpen(true);
    setSelectedState();
  };

  return (
    <>
      <HomeHeader activeLink="login" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className=" w-full flex justify-center items-center font-poppins relative py-[70px]">
          <div
            className="w-[550px] h-[946px] py-[31px] px-[29px] rounded-[20px]"
            style={{
              boxShadow: "0px 0px 25px 0px #00000026",
            }}
          >
            <h4 className="text-[36px] leading-[68px] font-bold text-center">
              Hospital Details
            </h4>

            <div className="mt-[60px]">
              <div className="my-8">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Hospital Name
                </p>
                <input
                  type="text"
                  className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                  placeholder="Hospital Full name"
                  value={hospitalName}
                  onChange={(e) => setHospitalName(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-2 w-full relative">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Country*
                </p>
                <div className="">
                  <div className="border-[1px] border-[#D0D5DD] bg-white px-[14px] h-[60px] w-full rounded-lg relative">
                    <input
                      type="text"
                      className="outline-none w-[80%] h-[60px] "
                      placeholder="Country"
                      onFocus={handleCountryFocus}
                      value={selectedCountry}
                      onChange={(e) => setCountryName(e.target.value)}
                    />
                    {isCountryOpen ? (
                      <RxCross2
                        className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                        onClick={() => setIsCountryOpen(false)}
                      />
                    ) : (
                      <FaAngleDown
                        className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                        onClick={() => setIsCountryOpen(true)}
                      />
                    )}
                  </div>
                </div>

                {isCountryOpen && (
                  <>
                    <div className=" absolute top-[100%] z-[101] overflow-y-scroll  h-[200px] bg-[white] text-black w-[95%]">
                      {countries?.map((data, index) => {
                        return (
                          <>
                            <p
                              className="ml-6 mt-3 cursor-pointer"
                              onClick={() => handleSelectCountry(data)}
                            >
                              {data}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-col gap-2 w-full relative mt-8">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Region/State/province*
                </p>

                <div className="">
                  <div className="border-[1px] border-[#D0D5DD] bg-white px-[14px] h-[60px]  w-full rounded-lg relative">
                    <input
                      type="text"
                      className="outline-none w-[80%] h-[60px] "
                      placeholder="Region/State/province"
                      onFocus={handleStateFocus}
                      value={selectedState}
                      onChange={(e) => setStateName(e.target.value)}
                    />
                    {isStateOpen ? (
                      <RxCross2
                        className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                        onClick={() => setIsStateOpen(false)}
                      />
                    ) : (
                      <FaAngleDown
                        className="absolute  top-4 left-[95%] text-[#667085] cursor-pointer"
                        onClick={() => setIsStateOpen(true)}
                      />
                    )}
                  </div>
                </div>

                {isStateOpen && (
                  <>
                    <div className=" absolute top-[100%] z-[101]  overflow-y-scroll right-1 max-h-[200px] bg-[white] text-black w-[95%]">
                      {states == "" && (
                        <>
                          <p className="text-center h-[70px] flex justify-center items-center">
                            No States Found
                          </p>
                        </>
                      )}
                      {states &&
                        states?.map((data, index) => {
                          return (
                            <>
                              <p
                                className="ml-6 mt-3 cursor-pointer"
                                onClick={() => handleSelectState(data)}
                              >
                                {data}
                              </p>
                            </>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>

              <div className="mt-8">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Admin Email Address
                </p>
                <input
                  type="text"
                  className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                  placeholder="johndoe@example.com"
                  value={hospitalEmail}
                  onChange={(e) => setHospitalEmail(e.target.value)}
                />
              </div>

              <div className="mt-8">
                <p className="font-[500] text-[20px] leading-[30px]">
                  Password
                </p>
                <input
                  type="text"
                  className="outline-none w-full border-[2px] rounded-[10px] border-[#EFEBEB] h-[60px] px-3 mt-2"
                  placeholder="Password"
                  value={hospitalPassword}
                  onChange={(e) => setHospitalPassword(e.target.value)}
                />
              </div>

              <button className="w-full h-[50px] bg-primary text-white mt-9 rounded-[10px]">
                Proceed to Payment Portal
              </button>
              <Link
                to="/login"
                className="w-full h-[50px] underline mt-5 flex justify-center items-center text-center"
              >
                Login to Account
              </Link>
            </div>
          </div>

          <img src={LoginSvg} className="absolute bottom-0 right-0" alt="" />
        </div>
      </div>
    </>
  );
};

export default SignUp;
