import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../Component/Patient/Header";
import HeroImg from "../../images/patient/hero.jpeg";
import HeroBlur from "../../images/patient/heroBlur.png";
import { TfiArrowCircleDown } from "react-icons/tfi";
import Feature1 from "../../images/patient/feature4.png";
import Feature3 from "../../images/patient/feature5.png";
import Feature2 from "../../images/patient/feature6.png";
import DemoImg from "../../images/patient/demo.png";
import Drawer from "../../images/patient/desk-drawer.png";
import Internet from "../../images/patient/wifi.png";
import Mug from "../../images/patient/mug.png";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import LatestUpdate from "../../Component/Patient/LatestUpdate";
import Footer from "../../Component/Patient/Footer";
import GlobalStorage from "../../Storage/ContextProvider";
import TestimonialHome from "../../Component/Patient/Testimonials/TestimonialHome";
import Blog1 from "../../images/patient/blog1.png";
// import RequestDemo from "../../Component/Patient/RequestDemo";
import { useNavigate } from "react-router-dom";
import Hero from "../../Component/LandingPage/Hero";
import Steps from "../../Component/LandingPage/Steps/Steps";
import Pilot from "../../Component/LandingPage/Pilot";
import Demo from "../../Component/LandingPage/Demo/Demo";
import FeatureMain from "../../Component/LandingPage/Feature/FeatureMain";
import RequestDemo from "../../Component/LandingPage/RequestDemo";
import Testmonial from "../../Component/LandingPage/Testmonial/Testmonial";
import Partner from "../../Component/LandingPage/Partner";
import HomeFooter from "../../Component/HomeFooter";
import HomeHeader from "../../Component/HomeHeader";
import StepsNew from "../../Component/LandingPage/Steps/StepsNew";
import HeroNew from "../../Component/LandingPage/HeroNew";

const PatientHome = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const {
    isOpen,
    setIsOpen,
    isRequestDemo,
    setRequestDemo,
    toggleProduct,
    setToggleProduct,
    activeMobileNav,
    setactiveMobileNav,
  } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const handleScollProduct = () => {
    navigate("/patient");
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (window.location.hash === "#sectionRef") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // Add more conditions for other sections if needed
  }, [toggleProduct]);

  const handleHomeButton = () => {
    navigate("/#sectionRef");
    setToggleProduct(!toggleProduct);
  };
  return (
    <>
      <div className="">
        {/* <Header /> */}
        <HomeHeader activeLink="home" />
        <div
          className={`${activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden": ""}`}
        >
          {/* <Hero /> */}
          <HeroNew />
          <StepsNew />
          {/* <Pilot /> */}
          <Demo />
        
         <Testmonial />
      
          <Partner />

          <FeatureMain />
          {/* <RequestDemo /> */}

          <HomeFooter />

          {/* <LatestUpdate />
            <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default PatientHome;
